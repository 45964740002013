import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'; 
// import { useDispatch } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { updateProduct,getProductDataByID } from "../../../services/Products/Product";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { styled } from "@mui/material/styles";
import Form from 'react-bootstrap/Form';
  const RequiredAsterisk = styled("span")({
    color: "red",
  });
const EditProducts = ({custDataRerander, isEditShow, close,editProductId }) => {
    const handleShow = () => setShow(true);
    const [productName, setProductName] = useState("");
    const [description, setDescription] = useState("");
    const [regularPrice, setRegularPrice] = useState("");
    const [salePrice, setSalePrice] = useState("");
    const [productData, setProductData] = useState([]);
    const [productid, setProductid] = useState("");
    const [productNameError, setProductNameError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [regularPriceError, setRegularPriceError] = useState(false);
    const [salePriceError, setSalePriceError] = useState(false);
    const [loading, setLoading] = useState(false);
    const productNameRef = useRef(null);
    const descriptionRef = useRef(null);
    const regularPriceRef = useRef(null);
    const salePriceRef = useRef(null);

    const handleSave = async() => {
        if (!productName.trim() || !description.trim() || !regularPrice || !salePrice ) {
          setProductNameError(!productName.trim())
          setDescriptionError(!description.trim())
          setRegularPriceError(!regularPrice)
          setSalePriceError(!salePrice)

    let object=[{
      key:productName.trim(),
      ref:productNameRef.current,

      },
      {
        key:description.trim(),
        ref:descriptionRef.current,
      },
      {
        key:regularPrice,
        ref:regularPriceRef.current,
      },
      {
        key:salePrice,
        ref:salePriceRef.current,
      },
    ]
    for(let i=0;i<object.length;i++){
      if (!object[i].key) {  
        object[i].ref.focus();
        break;
      } 
    }
     return
    }
    setProductNameError(false)
    setDescriptionError(false)
    setRegularPriceError(false)
    setSalePriceError(false)
       const updatedData = {
            productId:editProductId,
            name: productName,
            description: description,
            regular_price:regularPrice,
            sale_price: salePrice,
        };
        setLoading(true)
        const response =  await updateProduct(updatedData);
    if (response && response.data && response.data.status === true) {
            handleClose();
            custDataRerander();
            toast.success('Product updated Successfully');
            setLoading(false)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 4000);  
            // toast.error(`Error creating customer: ${response.data.errorMessage}`);
        }
    };

    useEffect(() => {
        getProductDataByID(editProductId)
        .then((response) => {
        if (response) { 
            setProductData(response.data);
            setProductName(response.data.name)
            setDescription(response.data.description)
            setRegularPrice(response.data.regular_price)
            setSalePrice(response.data.sale_price)
        }
        })
        .catch((error) => {
        toast.error('Error fetching customers:', error);
        });
    }, [editProductId]);

    const handleClose = () => { setShow(false); close() };
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(isEditShow)
    }, [isEditShow]);
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl"> 
            <Modal.Header closeButton>
                <h4><Icon.Cart4 /></h4>
                {/* <h5 className="modal-title"> Edit Product Data {editProductId}</h5> */}
                <h5 className="modal-title"> Edit </h5>
            </Modal.Header>
            <Modal.Body>
                <form className="row g-3 needs-validation" id="createCustomerForm" method="post" >
                    <div className="col-6 px-3">
                        <label htmlFor="NewCustomerName" className="form-label required">
                        <Icon.Cart4 /> Product Name <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="NewCustomerName"
                            name="customerName"
                            value={productName}
                            onChange={(e) => {setProductName(e.target.value)
                            if (productNameError && e.target.value.trim() !== '') {
                                  setProductNameError(false);
                             } }}
                            ref={productNameRef}
                            rows={3}
                            placeholder="Enter Product Name"
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: productNameError ? 'block' : 'none' }}>
                            Product name is required
                        </Form.Control.Feedback>
                        <div className="new-customer-name invalid-feedback">Please provide Customer Name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewMobileNumber" className="form-label required">
                        <Icon.CardText /> Description <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="NewMobileNumber"
                            name="mobileNumber"
                            value={description}
                            onChange={(e) => {setDescription(e.target.value)
                            if (descriptionError && e.target.value.trim() !== '') {
                                  setDescriptionError(false);
                             } }}
                            ref={descriptionRef}
                            rows={3}
                            placeholder="Enter Description"
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: descriptionError ? 'block' : 'none' }}>
                            Description is required
                        </Form.Control.Feedback>
                        <div className="new-mobile-number invalid-feedback">Please provide a valid Mobile Number.</div>
                    </div>

                    <div className="col-6 px-3">
                        <label htmlFor="NewGameName" className="form-label required"><Icon.CashCoin /> Regular Price <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input type="textt" className="form-control" name="name" id="NewGameName"value={regularPrice}
                             onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value) || value === "") {
                                    setRegularPrice(value);
                                    setRegularPriceError(false)
                                }else
                                setRegularPrice("")
                            }} ref={regularPriceRef} placeholder="Enter Regular Price" />
                            <Form.Control.Feedback type="invalid" style={{ display: regularPriceError ? 'block' : 'none' }}>
                            Regular Price is required
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please provide name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewGameName" className="form-label required"><Icon.CashCoin /> Sale Price <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input type="textt" className="form-control" name="name" id="NewGameName"value={salePrice}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value) || value === "") {
                                    setSalePrice(value);
                                    setSalePriceError(false)
                                }else
                                setSalePrice("")
                            }} ref={salePriceRef} placeholder="Enter Sale Price" />
                            <Form.Control.Feedback type="invalid" style={{ display: salePriceError ? 'block' : 'none' }}>
                            Sale Price required
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please provide name.</div>
                    </div>
                    <div className="modal-footer" >
                        <Button variant="danger approvebtn" disabled={loading}onClick={handleSave}>Save</Button>
                        <Button variant="danger rejectbtn" onClick={handleClose}> Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
};

export default EditProducts;
