import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'; 
// import { useDispatch } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { fetchStates ,FetchStateByPincode as fetchStateByPincodeApi} from "../../../services/Customer/customer";
import { getOutletDataByID, updateOutlet } from "../../../services/Outlet/Outlet";
import Form from 'react-bootstrap/Form';
import { styled } from "@mui/material/styles";
  const RequiredAsterisk = styled("span")({
    color: "red",
  });
const EditOutlet = ({custDataRerander, isEditShow, close,editUsersId }) => {
    const handleShow = () => setShow(true);
    const [outletName, setOutletName] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState();
    const [state, setState] = useState();
    const [stateName, setStateName] = useState("");
    const [stateData, setStateData] = useState([]);
    const [outletData, setoutletData] = useState([]);
    const [outletId, setOutletid] = useState("");
    const [outletNameError, setoutletNameError] = useState(false);
    const [addressError, setaddressError] = useState(false);
    const [pincodeError, setPincodeError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [loading, setLoading] = useState(false);
    const outletNameRef = useRef(null);
    const addressrRef = useRef(null);
    const pincodeRef = useRef(null);
    const stateRef = useRef(null);

    const handleSave = async() => {
         if (!outletName.trim() || !address.trim() ||  !pincode || !stateName) {
          setoutletNameError(!outletName.trim())
          setaddressError(!address.trim())
          setPincodeError(!pincode)  
          setStateError(!stateName)

        let object=[{
            key:outletName.trim(),
            ref:outletNameRef.current,
        },
        {
            key:address.trim(),
            ref:addressrRef.current,
        },
        {
            key:pincode,
            ref:pincodeRef.current,
        },
         {
            key:stateName,
            ref:stateRef.current,
        },
        ]
        for(let i=0;i<object.length;i++){
        if (!object[i].key) {  
            object[i].ref.focus();
            break;
        } 
        }
        return
        }
        setoutletNameError(false)
        setaddressError(false)
        setPincodeError(false)
        setStateError(false)

       const updatedData = {
            id:editUsersId,
            outlet_name: outletName,
            address: address,
            pincode:pincode,
            state_id:state,

        };
        setLoading(true)
        const response =  await updateOutlet(updatedData);
    if (response && response.data && response.data.status === true) {
            toast.success('Outlet Modified Successfully');
            handleClose();
            custDataRerander();
            setLoading(false)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 4000);  
            // toast.error(`Error modifying Outlet: ${response.data.errorMessage}`);
        }
    };
     
  const fetchStateByPincode = async (pincode) => {
        try {
            const stateResponse = await fetchStateByPincodeApi(pincode);
            setState(stateResponse.data.data.StateId.id);
            setStateName(stateResponse.data.data.StateId.state_name);
            setStateError(false)
        } catch (error) {
            toast.error('Pin code does not exist..!');
        }
    };

    useEffect(() => {
        getOutletDataByID(editUsersId)
        .then((response) => {
        if (response) {
            setoutletData(response.data);
            setOutletName(outletData.outlet_name)
            setAddress(outletData.address)
            setState(outletData.state)
            setPincode(outletData.pincode)
            setOutletid(outletData.id)
        }
        })
        .catch((error) => {
        console.error('Error fetching outlet:', error);
        });
    }, [outletId]);

    useEffect(()=>{
         fetchStates()
            .then((response) => {
                if (response) {
                    setStateData(response.data.data);
                }
            })
            .catch((error) => {
            //   toast.error('Error fetching customers:', error);
            });
    },[])

    useEffect(() => {
        // Check if pincode has exactly 6 digits
        if (pincode && pincode.toString().length === 6) {
            fetchStateByPincode(pincode);
        }
    }, [pincode]);

        const handleStateNameChange = (selected) => {
            setStateError(false)
         const selectedState = stateData.find((statee) => statee.state_name === selected[0]);
          setStateName(selected[0]);
        if (selectedState) {
           setState(selectedState.id);
            }
        };

    const handleClose = () => { setShow(false); close() };
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(isEditShow)
    }, [isEditShow]);
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl"> 
            <Modal.Header closeButton>
                <h4><Icon.PersonPlus /> </h4>
                {/* <h5 className="modal-title"> Edit Outlet Data {editUsersId}</h5> */}
                <h5 className="modal-title"> Edit</h5>
            </Modal.Header>
            <Modal.Body>
                <form className="row g-3 needs-validation" id="createCustomerForm" method="post" >
                    <div className="col-6 px-3">
                        <label htmlFor="NewCustomerName" className="form-label required">
                        <Icon.Person /> Outlet Name <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="NewCustomerName"
                            name="customerName"
                            value={outletName}
                            onChange={(e) => {
                             setOutletName(e.target.value);
                             if (outletNameError && e.target.value.trim() !== '') {
                                  setoutletNameError(false);
                             }
                            }} 
                            ref={outletNameRef}
                            placeholder="Enter Outlet Name"
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: outletNameError ? 'block' : 'none' }}>
                            Outlet name is required
                        </Form.Control.Feedback>
                        <div className="new-customer-name invalid-feedback">Please provide Customer Name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewMobileNumber" className="form-label required">
                        <Icon.Phone /> Address <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="NewMobileNumber"
                            name="mobileNumber"
                            value={address}
                            onChange={(e) => {setAddress(e.target.value)
                            if (addressError && e.target.value.trim() !== '') {
                                  setaddressError(false);
                             } }}
                            ref={addressrRef}
                            placeholder="Enter Address"
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: addressError ? 'block' : 'none' }}>
                            Address is required
                        </Form.Control.Feedback>
                        <div className="new-mobile-number invalid-feedback">Please provide a valid Mobile Number.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewGameDescription" className="form-label required">
                            <Icon.Geo/> Pincode <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input type="text" className="form-control" name="description" id="NewGameDescription" value={pincode}
                            onChange={(e) => {
                                setPincode(parseInt(e.target.value, 10) || '');
                                if (pincodeError && e.target.value.trim().match(/^\d{6}$/)) {
                                    setPincodeError(false);
                                }
                            }}
                            ref={pincodeRef} 
                            placeholder="Enter Pincode" />
                        <Form.Control.Feedback type="invalid" style={{ display: pincodeError ? 'block' : 'none' }}>
                            Pincode is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div> 
                    <div className="col-6 px-3">
                        <label htmlFor="NewGameDescription" className="form-label required"><Icon.GeoAlt/> State</label>
                         <Typeahead
                            id="stateName"
                            onChange={handleStateNameChange}
                            options={stateData.map((statee) => statee.state_name)}
                            placeholder="Select or Enter State"
                            selected={stateName ? [stateName] : []}
                            className="form-control"
                            style={{ marginBottom: '10px' }}
                        />
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div>
                    <div className="modal-footer" >
                        <Button variant="danger approvebtn" disabled={loading} onClick={handleSave}>Save</Button>
                        <Button variant="danger rejectbtn" onClick={handleClose}> Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>

    );
};

export default EditOutlet;
