import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'; 
// import { useDispatch } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { AddCustomers, fetchStates, FetchStateByPincode as fetchStateByPincodeApi} from "../../../services/Customer/customer";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Form from 'react-bootstrap/Form';
import { styled } from "@mui/material/styles";
  const RequiredAsterisk = styled("span")({
    color: "red",
  });
const AddCustomer = ({custDataRerander, isShow, close }) => {
    const handleShow = () => setShow(true);
    const [customerName, setCustomerName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [gstNumber, setGstNumber] = useState("");
    const [gstType, setGstType] = useState("");
    const [pincode, setPincode] = useState();
    const [billingPincode, setBillingPincode] = useState();
    const [billingAddress, setBillingAddress] = useState("");
    const [shippingAddress, setShippingAddress] = useState("");
    const [state, setState] = useState();
    const [billState, setBillState] = useState();
    const [stateName, setStateName] = useState("");
    const [bstateName, setBStateName] = useState("");
    const [stateData, setStateData] = useState([]);
    const [customerNameError, setCustomerNameError] = useState(false);
    const [mobileNumberError, setMobileNumberError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [gstNumberError, setgstNumberError] = useState(false);
    const [gstTypeError, setgstTypeError] = useState(false);
    const [pincodeError, setPincodeError] = useState(false);
    const [billingPincodeError, setBillingPincodeError] = useState(false);
    const [billingAddressError, setBillingAddresseError] = useState(false);
    const [shippingAddressError, setShippingAddressError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [bstateError, setBStateError] = useState(false);
    const [isSameAsBilling, setIsSameAsBilling] = useState(false);
    const [loading, setLoading] = useState(false);
    const customerNameRef = useRef(null);
    const mobileNumberRef = useRef(null);
    const emailRef = useRef(null);
    const gstNumberRef = useRef(null);
    const pincodeRef = useRef(null);
    const bpincodeRef = useRef(null);
    const billingAddressRef = useRef(null);
    const shippingAddressRef = useRef(null);
    const stateRef = useRef(null);
    const bstateRef = useRef(null);

    const handleSave = async() => {
        if (!customerName.trim() || !mobileNumber.trim() || !email.trim() || !gstNumber.trim() || !gstType || !pincode
        || !billingAddress.trim() || !shippingAddress.trim() || !stateName || !billingPincode || !bstateName) {
          setCustomerNameError(!customerName.trim())
          setMobileNumberError(!mobileNumber.trim())
          setEmailError(!email.trim())
          setgstNumberError(!gstNumber.trim())
          setgstTypeError(!gstType)
          setPincodeError(!pincode)  
          setBillingAddresseError(!billingAddress.trim())  
          setShippingAddressError(!shippingAddress.trim())
          setStateError(!stateName)  
          setBStateError(!billState)
          setBillingPincodeError(!billingPincode)

      let object=[{
      key:customerName.trim(),
      ref:customerNameRef.current,

      },
      {
        key:mobileNumber.trim(),
        ref:mobileNumberRef.current,
      },
      {
        key:email.trim(),
        ref:emailRef.current,
      },
      {
        key:gstNumber.trim(),
        ref:gstNumberRef.current,
      },
      {
        key:billingPincode,
        ref:bpincodeRef.current,
      },
      {
        key:pincode,
        ref:pincodeRef.current,
      },
      {
        key:billingAddress.trim(),
        ref:billingAddressRef.current,
      },
      {
        key:shippingAddress.trim(),
        ref:shippingAddressRef.current,
      },
      {
        key:stateName,
        ref:stateRef.current,
      },
      {
        key:bstateName,
        ref:bstateRef.current,
      },
    ]
    for(let i=0;i<object.length;i++){
      if (!object[i].key) {  
        object[i].ref.focus();
        break;
      } 
    }
     return
    }
    setCustomerNameError(false)
    setMobileNumberError(false)
    setEmailError(false)
    setgstNumberError(false)
    setgstTypeError(false)
    setPincodeError(false)
    setBillingAddresseError(false)
    setShippingAddressError(false)
    setStateError(false)
    setBillingPincodeError(false)
    setBStateError(false)
       const inputData = {
            customer_name: customerName,
            phone_number: mobileNumber,
            email:email,
            gst_number: gstNumber,
            gst_type: gstType,
            state:state,
            billingpincode:billingPincode,
            billingstate:billState,
            billing_address: billingAddress,
            shiping_address: shippingAddress,
            pincode:pincode 
        };
        setLoading(true)
        const response =  await AddCustomers(inputData);
    if (response && response.data && response.data.status === true) {
            handleClose();
            custDataRerander();
            toast.success('Customer Created Successfully');
            setLoading(false)
        } else {
            setTimeout(() => {
            setLoading(false)
          }, 4000);
        }
    };
     
  const fetchStateByPincode = async (pincode) => {
        try {
            const stateResponsee = await fetchStateByPincodeApi(pincode);
            setState(stateResponsee.data.data.StateId.id);
            setStateName(stateResponsee.data.data.StateId.state_name);
            setStateError(false)
        } catch (error) {
            toast.error('Please enter valid pincode');
        }
    };
     const fetchStateByBillPincode = async (billingPincode) => {
        try {
            const stateResponse = await fetchStateByPincodeApi(billingPincode);
            setBillState(stateResponse.data.data.StateId.id);
            setBStateName(stateResponse.data.data.StateId.state_name);
            setBStateError(false)
        } catch (error) {
            toast.error('Please enter valid pincode for billing state');
        }
    };

    useEffect(()=>{
         fetchStates()
    .then((response) => {
      if (response) {
        setStateData(response.data.data);
      }
    })
    .catch((error) => {
    //   toast.error('Error fetching customers:', error);
    });
    },[])

   useEffect(() => {
    // Check if pincode has exactly 6 digits
    if (pincode && pincode.toString().length === 6) {
        fetchStateByPincode(pincode);
    }
    }, [pincode]);

    useEffect(() => {

    // Check if pincode has exactly 6 digits
    if (billingPincode && billingPincode.toString().length === 6) {
        fetchStateByBillPincode(billingPincode);
    }
    }, [billingPincode]);

        const handleBillingStateNameChange = (selected) => {
         const selectedState = stateData.find((statee) => statee.state_name === selected[0]);
          setBStateName(selected[0]);
          setBStateError(false)
        if (selectedState) {
           setBillState(selectedState.id);
            }
        };
        const handleStateNameChange = (selected) => {
         const selectedState = stateData.find((statee) => statee.state_name === selected[0]);
          setStateName(selected[0]);
          setStateError(false)
        if (selectedState) {
           setState(selectedState.id);
            }
        };
        const handleSameAddressChange = (e) => {
            if (e.target.checked) {
                setIsSameAsBilling(true)
                setShippingAddress(billingAddress);
                setPincode(billingPincode)
                setPincodeError(false)
                setShippingAddressError(false)

            } else {
                setIsSameAsBilling(false)
                setShippingAddress('');
                setPincode('')
                setStateName('')
            }
        };


    const handleClose = () => { setShow(false); close() };
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(isShow)
    }, [isShow]);
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl"> 
            <Modal.Header closeButton>
                <h4><Icon.PersonPlus /></h4>
                <h5 className="modal-title"> Add Customer Data</h5>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete="off" className="row g-3 needs-validation" id="createCustomerForm" method="post" >
                    <div className="col-6 px-3">
                        <label htmlFor="NewCustomerName" className="form-label required">
                        <Icon.Person /> Customer Name <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            id="NewCustomerName"
                            name="customerName"
                            value={customerName}
                            ref={customerNameRef}
                            onChange={(e) => {setCustomerName(e.target.value)
                            if (customerNameError && e.target.value.trim() !== '') {
                                  setCustomerNameError(false);
                             } }}
                            placeholder="Enter Customer Name"
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: customerNameError ? 'block' : 'none' }}>
                            Customer name is required
                        </Form.Control.Feedback>
                        <div className="new-customer-name invalid-feedback">Please provide Customer Name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewMobileNumber" className="form-label required">
                        <Icon.Phone /> Mobile Number <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            type="text"
                            autoComplete="off"
                            className="form-control"
                            id="NewMobileNumber"
                            name="mobileNumber"
                            ref={mobileNumberRef}
                            value={mobileNumber}
                            onChange={(e) => {setMobileNumber(e.target.value)
                            if (mobileNumberError && e.target.value.trim() !=='' && !isNaN(e.target.value.trim())) {
                                setMobileNumberError(false)
                            }}}  
                            placeholder="Enter Mobile Number"
                            pattern="[0-9]*" 
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: mobileNumberError ? 'block' : 'none' }}>
                            Mobile number is required
                        </Form.Control.Feedback>
                        <div className="new-mobile-number invalid-feedback">Please provide a valid Mobile Number.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewGameName" className="form-label required">
                            <Icon.Envelope /> Email-ID <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input type="text"  autoComplete="off" className="form-control" name="name" id="NewGameName"value={email}
                            onChange={(e) => {setEmail(e.target.value)
                            if (emailError && e.target.value.trim() !=='') {
                                setEmailError(false)
                            }}} ref={emailRef} placeholder="Enter Customer Email-ID" />
                        <Form.Control.Feedback type="invalid" style={{ display: emailError ? 'block' : 'none' }}>
                            Email-ID is required
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please provide name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewGameName" className="form-label required">
                            <Icon.CashCoin /> GST Number <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input type="text"  autoComplete="off" className="form-control" name="gst" id="gst"value={gstNumber}
                            onChange={(e) => {setGstNumber(e.target.value)
                            if (gstNumberError && e.target.value.trim() !=='') {
                                setgstNumberError(false)
                            }}} ref={gstNumberRef} placeholder="Enter GST Number" />
                        <Form.Control.Feedback type="invalid" style={{ display: gstNumberError ? 'block' : 'none' }}>
                            GST Number required
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please provide name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="for builling" className="form-label required">
                            <Icon.Geo/> Billing Address Pincode <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input type="text"  autoComplete="off" className="form-control" name="billaddress" id="billaddress" value={billingPincode}
                           onChange={(e) => {setBillingPincode(parseInt(e.target.value,10)||'')
                            if (billingPincodeError && e.target.value.trim() !=='' && !isNaN(e.target.value.trim())) {
                                setBillingPincodeError(false)
                            }}}  ref={bpincodeRef} placeholder="Enter Billing Address Pincode" />
                        <Form.Control.Feedback type="invalid" style={{ display: billingPincodeError ? 'block' : 'none' }}>
                         Billing Address Pincode is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div> 
                     <div className="col-6 px-3">
                        <label htmlFor="NewGameDescription" className="form-label required">
                            <Icon.Geo/> Shipping Address Pincode <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input type="text"  autoComplete="off" className="form-control" name="shipaddress" id="shipaddress" value={pincode}
                            onChange={(e) => {setPincode(parseInt(e.target.value,10)||'')
                            if (pincodeError && e.target.value.trim() !=='' && !isNaN(e.target.value.trim())) {
                                setPincodeError(false)
                            }}}  ref={pincodeRef} placeholder="Enter Shipping Address Pincode" />
                        <Form.Control.Feedback type="invalid" style={{ display: pincodeError ? 'block' : 'none' }}>
                           Shipping Address Pincode is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div> 
                    <div className="col-6 px-3">
                        <label className="form-label required">
                            <Icon.GeoAlt/> Billing State <RequiredAsterisk>*</RequiredAsterisk></label>
                         <Typeahead
                            id="stateNamebilling"
                            onChange={handleBillingStateNameChange}
                            ref={bstateRef}
                            options={stateData.map((statee) => statee.state_name)}
                            placeholder="Select or Enter State"
                            selected={bstateName ? [bstateName] : []}
                            className="form-control"
                            style={{ marginBottom: '10px' }}
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: bstateError ? 'block' : 'none' }}>
                          Billing State is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div> 
                    <div className="col-6 px-3">
                        <label className="form-label required">
                            <Icon.GeoAlt/> Shipping State <RequiredAsterisk>*</RequiredAsterisk></label>
                         <Typeahead
                            id="stateName"
                            onChange={handleStateNameChange}
                            ref={stateRef}
                            options={stateData.map((statee) => statee.state_name)}
                            placeholder="Select or Enter State"
                            selected={stateName ? [stateName] : []}
                            className="form-control"
                            style={{ marginBottom: '10px' }}
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: stateError ? 'block' : 'none' }}>
                           Shipping State is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div>

                    <div className="col-6 px-3">
                        <label htmlFor="NewBillingAddress" className="form-label required">
                        <Icon.Buildings/> Billing Address <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <textarea
                            className="form-control"
                            autoComplete="off"
                            name="billingAddress"
                            id="NewBillingAddress"
                            value={billingAddress}
                            onChange={(e) => {setBillingAddress(e.target.value)
                            if (billingAddressError && e.target.value.trim() !=='') {
                                setBillingAddresseError(false)
                            }}}
                            ref={billingAddressRef}
                            placeholder="Enter Billing Address"
                            rows="3"
                            style={{ resize: "none" }}
                        ></textarea>
                        <Form.Control.Feedback type="invalid" style={{ display: billingAddressError ? 'block' : 'none' }}>
                            Billing address is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Billing Address.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="shipp" className="form-label required">
                        <Icon.Buildings/> Shipping Address <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <textarea
                            autoComplete="off"
                            className="form-control"
                            name="shippingAddress" 
                            id="NewShippingAddress" 
                            value={shippingAddress}
                            onChange={(e) => {setShippingAddress(e.target.value)
                            if (shippingAddressError && e.target.value.trim() !=='') {
                                setShippingAddressError(false)
                            }}}
                            ref={shippingAddressRef}
                            placeholder="Enter Shipping Address"
                            rows="3"
                            style={{ resize: "none" }}
                        ></textarea>
                        <Form.Control.Feedback type="invalid" style={{ display: shippingAddressError ? 'block' : 'none' }}>
                            Shipping address is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Billing Address.</div>
                    </div>
                <div className="col-12 px-3">
                    <div className="form-check">
                        <input
                            autoComplete="off"
                            className="form-check-input"
                            type="checkbox"
                            id="sameAsBillingAddress"
                            onChange={handleSameAddressChange}
                            checked={isSameAsBilling}
                        />
                        <label className="form-check-label" htmlFor="sameAsBillingAddress">
                            Shipping address is the same as billing address
                        </label>
                    </div>
                </div>
                <div className="col-6 px-3">
                        <label htmlFor="NewGSTType" className="form-label required">
                        <Icon.CashCoin /> GST Type <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <select className="form-select" name="gstType" id="NewGSTType"
                         value={gstType}
                            onChange={(e) => {setGstType(e.target.value)
                            if (gstTypeError && e.target.value.trim() !=='') {
                                setgstTypeError(false)
                            }}}
                        >
                            <option value="">Select GST Type</option>
                            <option value="SGST">SGST</option>
                            <option value="CGST">CGST</option>
                            <option value="IGST">IGST</option>
                        </select>
                        <Form.Control.Feedback type="invalid" style={{ display: gstTypeError ? 'block' : 'none' }}>
                            Please select GST Type
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please select GST Type.</div>
                    </div>
                    <div className="modal-footer" >
                        <Button variant="danger approvebtn" disabled={loading} onClick={handleSave}>Save</Button>
                        <Button variant="danger rejectbtn" onClick={handleClose}> Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>

    );
};

export default AddCustomer;
