import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'; 
import { useNavigate } from "react-router-dom";
import AddUser from "./popup/addUser";
import EditUser from "./popup/editUser";
import { deleteCustomersById } from "../../services/Customer/customer";
import './Usersmaster.css'; 
import { getEnvUrl,getToken } from "../../utils/Api";
import {  deleteUserById } from "../../services/User/User";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
const $ = require('jquery')
$.DataTable = require('datatables.net')
function Employeemaster() { 
  const [editUsersId, setEditUsersId] = useState(''); 
  const [AddcustomerModal, setAddcustomerModal] = useState(false);
  const [EditcustomerModal, setEditcustomerModal] = useState(false); 
  const [searchValue, setSearchValue] = useState('');
  const [searchLocation, setSearchLocation] = useState(''); 
  const [tableRerander, setTableRerander] = useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      processing: true,
      serverSide: true,
      serverMethod: "get",
      ajax: {
        url: `${getEnvUrl()}/users/usersData?search=${encodeURIComponent(searchValue)}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        dataSrc: function (json) { 
          if(json.errorMessage!=undefined && (json.errorMessage=='Permission denied' || json.errorMessage === "Invalid token")){
           localStorage.clear();  
           navigate('/login')
          } else{
            return json.aaData
          } 
        }
   },
      columns: [
        { data: "id" },
        { data: "emp_code" },
        { data: "name" },
        { data: "user_type" },
        { data: "reportingTo_name"},
        { data: "email" },
        { data: "branch_name" },
        { data: "active" },  
        { 
          data: null,
          title: 'Actions',
          render: (data, type, row) => {
            return `
            <div class="btn-group" role="group" aria-label="Basic example">
              <button class="btn btn-danger approvebtn">Edit</button>
              <button class="btn btn-danger ${row.active?'rejectbtn':'activatebtn'}">${row.active?'Deactivate':'Activate'}</button>
            </div>
          `; 
        },
      }
      ],
      'columnDefs': [ {
        'targets': [8], /* column index */
        'orderable': false, /* true or false */ 
     }],
     createdRow: (row, data, dataIndex) => {              
        $(row).find('.approvebtn').on('click', () => {          
          setEditUsersId(data.id)
          setEditcustomerModal(true)
        });     
        
        var rowobj=$(row) 
        $(row).find('.rejectbtn,.activatebtn').on('click', function(){  
          var clsName=$(this).attr('class')
          var obj=$(this)
          var rowobj=$(row)  
          deleteUserById(data.id).then((res)=>{
            if(res.status){
              toast.success(res.message)
              if(clsName.includes('rejectbtn')){
                rowobj.find('td').eq(7).text('false')
                 obj.removeClass('rejectbtn').addClass('activatebtn').text('Activate')
                
              }else{
                rowobj.find('td').eq(7).text('true') 
                obj.removeClass('activatebtn').addClass('rejectbtn').text('Deactivate') 
                 
              } 
            }else{
              toast.find('td').success('Somthing went worng')
             }
          }).catch((error)=>{
            toast.success('Somthing went worng')
          })
          // custDataRerander();
        });
      },
      drawCallback: function(settings) {
        // setIsLoading(false);
      },
      aaSorting: [[0, "desc"]],
      
    }); 
    return () => {
      // Destroy DataTable when component unmounts to avoid memory leaks
      // table.destroy();
    };
  }, [tableRerander,searchValue]); 
 
  let AddcustomerModalClose = () => {
    setAddcustomerModal(false)
  }
  let EditcustomerModalClose = () => {
    setEditcustomerModal(false)
  }
  
  let custDataRerander = () => {
    setTableRerander(!tableRerander)
  } 

  const divStyles = {
    boxShadow: '1px 2px 9px #000',
    'marginBottom': '20px',
    padding: '1em',
  };
  return (
    <>
     <div>
     <div class="pagetitle"><h1>User Master</h1><nav></nav></div>
      <div style={divStyles}>
        <Row>
          <Col xl={12} lg={12}>
            <Row>
              {/* <Col>
                  <Form.Control
                    type="text"
                    placeholder="Search by name"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
              </Col> */}
              {/* <Col className="mb-1">
                <FloatingLabel controlId="floatingInput">
                  <Button
                    variant="danger"
                    style={{ backgroundColor: '#FD1616', borderRadius: '30px', fontWeight: 500 }}
                    onClick={() => {
                      setSearchValue("")
                    }}
                  >
                    <Icon.Funnel color="black" size={15} /> Reset Filter
                  </Button>
                </FloatingLabel>
                  </Col> */}
              <Col className="d-flex justify-content-end">
                <FloatingLabel controlId="floatingInput">
                  <Button
                    variant="danger"
                    className="AddNewCustomer"
                    style={{ backgroundColor: '#FD1616', borderRadius: '30px', fontWeight: 500 }}
                    onClick={() => { setAddcustomerModal(true) }} >
                    <Icon.PersonAdd color="black" size={15} />  Add User
                  </Button>
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
      <div>
        <Row>
          <Col xl={12} lg={12}>

            <div className="card-body table-responsive">
              <table
                className="table table-striped table-hover table-bordered no-footer dataTable table-responsive dataTable-container"
                width="100%"
                ref={tableRef}
              >
                <thead>
                  <tr className="header">
                    <th style={{'width':'60px'}}>User ID</th>
                    <th style={{'width':'60px'}}>Emp Code</th>
                    <th>User Name</th>
                    <th>Role</th>
                    <th>Reporting TO</th>
                    <th>Email</th>
                    <th>Branch</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
            <div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <AddCustomer /> */}
      {AddcustomerModal ? <AddUser 
      custDataRerander={custDataRerander}
      isShow={AddcustomerModal} 
      close={AddcustomerModalClose} /> : ''}

    {EditcustomerModal ? <EditUser 
      custDataRerander={custDataRerander}     
      isEditShow={EditcustomerModal} 
      close={EditcustomerModalClose}
      editUsersId={editUsersId} /> : ''}  
    </div>
    
     </>
   

  )
  
}

export default Employeemaster;