import { data } from "jquery";
import { getEnvUrl, getToken, axiosInstance } from "../../utils/Api";
import { getUserToken } from "../User/User";
import axios from 'axios';
import { toast } from "react-toastify";


export const updateOrder = async (inputData) => {
    try {
        const response = await axiosInstance.put('/orders/updateOrder', inputData, {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Update Order:', error);
        return false;
    }
};

export const createOrder = async (inputData) => {
    try {
        const response = await axiosInstance.post('/orders/createOrder', inputData, {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Create Order:', error);
        return false;
    }
};

export const fetchOutlets = async () => {
    try {
        const response = await axiosInstance.get('/outlet/getOutletdata', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Outlets:', error);
        return false;
    }
};

export const searchOutletsByName = async (outlet_name) => {
    try {
        const response = await axiosInstance.get('/outlet/searchOutletsByName', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { outlet_name: outlet_name },
        });
        if (response.data.status === true) {
            return response;
        }
        return;
    } catch (error) {
        console.error('Error in Search Outlets By Name:', error);
    }
};

export const fetchOrderId = async () => {
    try {
        const response = await axiosInstance.get('/orders/getOrderId', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Order ID:', error);
        return false;
    }
};

export const searchProducts = async (productname, productids) => {
    try {
        const response = await axiosInstance.get('/products/searchProduct', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { product_name: productname, productids: productids },
        });
        return response;
    } catch (error) {
        console.error('Error in Search Products:', error);
        return false;
    }
};

export const fetchUsers = async () => {
    try {
        const response = await axiosInstance.get('/users/getAllUser', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Users:', error);
        return false;
    }
};

export const fetchOrderDetailsById = async (orderId, edit = null) => {
    try {
        const response = await axiosInstance.get(`/orders/getOrderDetails/${orderId}?edit=${edit}`, {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error in Fetch Order Details By ID:', error);
        return false;
    }
};

export const orderStatusUpdate = async (data) => {
    try {
        const response = await axiosInstance.put('/orders/statusUpdate', data, {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error in Order Status Update:', error);
        return false;
    }
};

export const getOrderStatuses = async () => {
    try {
        const response = await axiosInstance.get('/orders/getRoles', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Get Order Statuses:', error);
        return false;
    }
};

export const orderDashboard = async () => {
    try {
        const response = await axiosInstance.get('/orders/dashboard', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error in Order Dashboard:', error);
        return false;
    }
};