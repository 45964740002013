import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Button from 'react-bootstrap/Button'; 
import AddCustomer from "./popup/addCustomer";
import EditCustomer from "./popup/editCustomer";
import { deleteCustomersById } from "../../services/Customer/customer";
import './Customermaster.css'; 
import { useNavigate } from "react-router-dom";
import { getEnvUrl,getToken } from "../../utils/Api";
const $ = require('jquery')
$.DataTable = require('datatables.net')
function Customermaster() { 
  const [editCustomerId, setEditCustomerId] = useState('');  
  const [EditcustomerModal, setEditcustomerModal] = useState(false); 
  const [popupType, setPopupType] = useState(''); 
  const [tableRerander, setTableRerander] = useState(false);
  const navigate = useNavigate();
  const tableRef = useRef(null);
  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      processing: true,
      serverSide: true,
      serverMethod: "get",
      ajax: {
        url: `${getEnvUrl()}/customer/customerData`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        dataSrc: function (json) { 
          if(json.errorMessage!=undefined && (json.errorMessage=='Permission denied' || json.errorMessage === "Invalid token")){
           localStorage.clear();  
           navigate('/login')
          } else{
            return json.aaData
          } 
       }
      },
      columns: [
        { data: "id" },
        { data: "customer_name" },
        { data: "state_name" },
        { data: "phone_number" },
        { data: "email" },
        { data: "status" }, 
        { 
          data: null,
          title: 'Actions',
          render: (data, type, row) => {
            return `
            <div class="btn-group" role="group" aria-label="Basic example">
              <button class="btn btn-danger approvebtn">Edit</button>
              <button class="btn btn-danger ${row.status?'rejectbtn':'activatebtn'}">${row.status?'Deactivate':'Activate'}</button>
            </div>
          `;  
        },
        },
      ],
      'columnDefs': [ {
        'targets': [6], /* column index */
        'orderable': false, /* true or false */ 
     }],
     createdRow: (row, data, dataIndex) => {              
        $(row).find('.approvebtn').on('click', () => {          
          setEditCustomerId(data.id)
          setEditcustomerModal(true)
          setPopupType('EDIT')
        });
        $(row).find('.rejectbtn,.activatebtn').on('click', function() { 
          var clsName=$(this).attr('class')
          var obj=$(this)
          var rowobj=$(row)  
          deleteCustomersById(data.id).then((res)=>{
            if(res.status){
              toast.success(res.message)
             if(clsName.includes('rejectbtn')){
                rowobj.find('td').eq(5).text('false')
                obj.removeClass('rejectbtn').addClass('activatebtn').text('Activate')
              }else{
                rowobj.find('td').eq(5).text('true')
                obj.removeClass('activatebtn').addClass('rejectbtn').text('Deactivate')  
              }
               
             }else{
              toast.success('Somthing went worng')
             }
          }).catch((error)=>{
            toast.success('Somthing went worng')
          })
          // custDataRerander();
        }); 
      },
      drawCallback: function(settings) {
        // setIsLoading(false);
      },
      aaSorting: [[0, "desc"]],
      
    }); 
    return () => {
      // Destroy DataTable when component unmounts to avoid memory leaks
      // table.destroy();
    };
  }, [tableRerander]); 
 
  
  let EditcustomerModalClose = () => {
    setEditcustomerModal(false)
  }
  
  let custDataRerander = () => {
    setTableRerander(!tableRerander)
  } 

  const divStyles = {
    boxShadow: '1px 2px 9px #000',
    'marginBottom': '20px',
    padding: '1em',
  };
  return (
    <div> 
      <div class="pagetitle"><h1>Customer Master</h1><nav></nav></div>
      <div style={divStyles}>
        <Row>
          <Col xl={12} lg={12}>
            <Row>
              <Col className="d-flex justify-content-end">
                <FloatingLabel controlId="floatingInput">
                  <Button
                    variant="danger"
                    className="AddNewCustomer"
                    style={{ backgroundColor: '#FD1616', borderRadius: '30px', fontWeight: 500 }}
                    onClick={() => { setEditcustomerModal(true);setPopupType('CREATE') }} >
                    <Icon.PersonAdd color="black" size={15} />  Add Customer
                  </Button>
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
      <div>
        <Row>
          <Col xl={12} lg={12}>

            <div className="card-body">
              <table
                className="table table-striped table-hover table-bordered no-footer dataTable"
                width="100%"
                ref={tableRef}
              >
                <thead>
                  <tr className="header">
                    <th style={{'width':'60px'}}>Customer ID</th>
                    <th>Customer Name</th>
                    <th>Location</th>
                    <th>Contact Number</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
            <div>
            </div>
            
          </Col>
        </Row>
      </div>
      

    {EditcustomerModal ? <EditCustomer 
      custDataRerander={custDataRerander}     
      isEditShow={EditcustomerModal} 
      close={EditcustomerModalClose}
      editCustomerId={editCustomerId} 
      type={popupType}
      /> : ''}  
    </div>

  )
  
}

export default Customermaster;