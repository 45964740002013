import { data } from "jquery";
import { getEnvUrl, getToken, axiosInstance } from "../../utils/Api";
import { getUserToken } from "../User/User";
import axios from 'axios';
import { toast } from "react-toastify";

export const AddOutlet = async (inputData) => {
    try {
        const response = await axiosInstance.post('/outlet/createOutlet', inputData, {
            headers: {
                Authorization: getUserToken(),
            },
        });

        if (response.data.status === true) {
            return response;
        }
        toast.error(response.data.errorMessage);
        return response;
    } catch (error) {
        console.error('Error in Add Outlet:', error);
        return false;
    }
};

export const fetchOutlets = async () => {
    try {
        const response = await axiosInstance.get('/outlet/outletsdata', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Outlets:', error);
        return false;
    }
};

export async function getOutletDataByID(editUserId) {
    try {
        const response = await axiosInstance.get('/outlet/getOutletbyid', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { id: editUserId },
        });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Error fetching data:', response.status, response.statusText);
            throw new Error(`Error fetching data: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export const updateOutlet = async (updatedData) => {
    try {
        const response = await axiosInstance.put('/outlet/updateOutlet', updatedData, {
            headers: {
                Authorization: getUserToken(),
            },
        });

        if (response.data.status === true) {
            return response;
        }
        return toast.error(response.data.errorMessage);
    } catch (error) {
        console.error('Error in Edit Outlet:', error);
        return false;
    }
};

export const deleteOutletById = async (deleteCustomerId) => {
    try {
        const response = await axiosInstance.delete('/outlet/deleteOutlet', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { id: deleteCustomerId },
        });
        return response.data;
    } catch (error) {
        console.error('Error in Delete Outlet By ID:', error);
        return false;
    }
};