import { data } from "jquery";
import { getEnvUrl, getToken, axiosInstance } from "../../utils/Api";
import { getUserToken } from "../User/User";
import axios from 'axios';
import { toast } from "react-toastify";

export const AddCustomers = async (inputData) => {
    try {
        const response = await axiosInstance.post('/customer/createCustomer', inputData, {
            headers: {
                Authorization: getUserToken(),
            },
        });

        if (response && response.data && response.data.status === true) {
            return response;
        } else {
            return toast.error(response.data.errorMessage);
        }
    } catch (error) {
        console.error('Error in Add Customers:', error);
    }
};

export const updateCustomers = async (updatedData) => {
    try {
        const response = await axiosInstance.put('/customer/updateCustomer', updatedData, {
            headers: {
                Authorization: getUserToken(),
            },
        });

        if (response.data.status === true) {
            return response;
        }
        return toast.error(response.data.errorMessage);
    } catch (error) {
        console.error('Error in Edit Customers:', error);
    }
};

export const fetchCustomers = async () => {
    try {
        const response = await axiosInstance.get('/customer/getcustomerdata', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Customers:', error);
        return false;
    }
};

export const fetchCustomersById = async (editCustomerId) => {
    try {
        const response = await axiosInstance.get('/customer/getcustomerbyid', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { id: editCustomerId },
        });
        return response.data;
    } catch (error) {
        console.error('Error in Fetch Customer By ID:', error);
        return false;
    }
};

export const searchCustomers = async (customer_name) => {
    try {
        const response = await axiosInstance.get('/customer/searchCustomerByName', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { customer_name: customer_name },
        });

        if (response.data.status === true) {
            return response;
        }
        return;
    } catch (error) {
        console.error('Error in Fetch Customer By Name:', error);
    }
};

export const deleteCustomersById = async (deleteCustomerId) => {
    try {
        const response = await axiosInstance.delete('/customer/deletecustomer', {
            headers: {
                Authorization: getUserToken(),
            },
            data: { id: deleteCustomerId },
        });
        return response.data;
    } catch (error) {
        console.error('Error in Delete Customer By ID:', error);
        return false;
    }
};

export const fetchStates = async () => {
    try {
        const response = await axiosInstance.get('/customer/getAllState', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch States:', error);
        return false;
    }
};

export const FetchStateByPincode = async (pincode) => {
    try {
        const response = await axiosInstance.get('/customer/getState', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { pincode: pincode },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch State By Pincode:', error);
        return false;
    }
};