import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap'
import Orderview from "../common/Orderview";
import './DraftRequests.css'
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { getEnvUrl,getToken } from "../../utils/Api";
import Searchbar from "../common/SearchBar";
const $ = require('jquery')
$.DataTable = require('datatables.net')
function DraftRequests() { 
  const navigate  = useNavigate();
  const [rowData,setRowData]=useState({})
  const [searchParams, setSelectedDate] = useState({
    order_status:'',
    customer_name:'',
    order_id:'',
    se_id:'',
    start_date:'',
    end_date:'' 
  }); 
  function objectToQueryString(obj) {
    return Object.entries(obj)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value).trim())}`)
      .join('&');
  } 
  const [OrderviewModal, setOrderviewModal] = useState(false);
  const [viewOrderId, setviewOrderId] = useState(false); 
  const [tableRender, setTableRender] = useState(false);  
  let resetSearch=(obj)=>{ 
    setSelectedDate(obj)
    setTableRender(!tableRender)
  }

  let downloadReport=(obj)=>{  
    window.location.href = `${getEnvUrl()}/orders/downloadReport?t=${getToken()}&status=DRAFT&${objectToQueryString(obj)}`;
  }
const tableRef = useRef(null);
useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      processing: true,
      serverSide: true,
      serverMethod: "get",
      ajax: {
        url: `${getEnvUrl()}/orders/getOrders?status=DRAFT&${objectToQueryString(searchParams)}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        dataSrc: function (json) { 
          if(json.errorMessage!=undefined && (json.errorMessage=='Permission denied' || json.errorMessage === "Invalid token")){
           localStorage.clear();  
           navigate('/login')
          } else{
            return json.aaData
          } 
       }
   },
      columns: [
        { data: "orderId" },
        { data: "outlet_name" },
        { data: "customerName" },
        { data: "se_name" },
        { data: "orderDate" },
        { data: "dueDate" },          
        { data: "orderAmount" },     
        { data: "status_name" },
        { 
          data: null,
          title: 'Actions',
          render:  (data, type, row) => (
            `<div class="btn-group" role="group" aria-label="Basic example">
              <Button class="btn btn-danger approvebtn" > View</Button> 
              <Button class="btn btn-danger rejectbtn" > Edit</Button> 
            </div>
            ` )
        },
            
      ],
      'columnDefs': [ {
        'targets': [8], /* column index */
        'orderable': false, /* true or false */ 
     }],
     createdRow: (row, data, dataIndex) => {              
        $(row).find('.approvebtn').on('click', () => {
          setviewOrderId(data.orderId)
          setRowData(JSON.stringify(data)) 
          setOrderviewModal(true); 
        });     
        $(row).find('.rejectbtn').on('click', () => { 
          navigate(`/editorder/${btoa(data.orderId)}`);
        });
      },
      drawCallback: function(settings) {
        // setIsLoading(false);
      },
      aaSorting: [[0, "desc"]],      
    }); 
    return () => {
      // Destroy DataTable when component unmounts to avoid memory leaks
      // table.destroy();
    };
  }, [tableRender]);
  let OrderviewModalClose = () => {
    setOrderviewModal(false)
  }
  
  return (
    <div> 
        <div class="pagetitle"><h1>Draft Requests</h1><nav></nav></div>
       <Searchbar 
       downloadReport={downloadReport}
       status="DRAFT"
       searchObj={searchParams} resetSearch={resetSearch}/>
      <div>
        <Row>
          <Col xl={12} lg={12}>
            <div className="card-body">
              <table
                className="table table-striped table-hover table-bordered no-footer dataTable"
                width="100%"
                ref={tableRef}
              >
                <thead>
                  <tr className="header">
                    <th>Order Id {OrderviewModal}</th>
                    <th>Outlet</th>
                    <th>Customer Name</th>                 
                    <th>Sales person name</th>
                    <th>Order date</th>
                    <th>Due date</th>
                    <th>Total price</th>
                    <th>Status</th> 
                    <th style={{width:"150px"}}>Action</th>
                  </tr>
                </thead>
                <tbody> 
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div> 
      {OrderviewModal ? <Orderview 
      isShow={OrderviewModal} 
      close={OrderviewModalClose}
      viewOrderId={viewOrderId} 
      rowData={rowData}
      resetTableRender={()=>setTableRender(!tableRender)} 
       /> : ''}
    <div>
    <br></br>
    <br></br>
    </div>
    </div>
 
  )
}

export default DraftRequests