import React,{useState,useEffect} from "react";
import { Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';
import {getOrderStatuses} from '../../services/Order/Order' 
import { Typeahead } from 'react-bootstrap-typeahead';
import { searchusers } from "../../services/User/User";
function Searchbar({status,searchObj,resetSearch,downloadReport}){
  let [orderStatus,setOrderStatus]=useState([])
  const [searchParams, setSelectedDate] = useState(searchObj); 
  const [searchUserName,setSearchUserName]=useState('')
  const [userData,setUserData]=useState([])
  const [filteredSalesExecutiveOptions, setFilteredSalesExecutiveOptions] = useState([]);
  const [salesExecutiveName, setSalesExecutiveName] = useState('');
  const [showDownload, setShowDownload] = useState(false);
  const [showserchbar, setShowserchbar] = useState(false);

  
  // Function to handle date change
  const handleDateChange = (event) => { 
   let name=event.target.name;
   let value=event.target.value; 
    setSelectedDate(prevState=>{ 
      return {...prevState,[name]:value}
    })
  };
  let checkDateValidation=()=>{
    let error=0;
    if(!searchParams.start_date && !searchParams.end_date){
      return true;
    }else{
      if(!searchParams.start_date){
        alert('Please select the start date.')
        error=error+1;
      }
      if(!searchParams.end_date){
        alert('Please select the end date.')
        error=error+1;
      }
      if(searchParams.start_date && searchParams.end_date && searchParams.end_date < searchParams.start_date){
        alert('The end date must be later than the start date.')
        error=error+1;
      }
      if(!error){
        return true
      }
    }
   
  }
  let search=()=>{ 
    if(checkDateValidation()){
      resetSearch(searchParams)
      const hasValue = Object.values(searchParams).some(param => param !== '');
     setShowDownload(hasValue);
    }
    
  }
  
  let reportDownload=()=>{  
    if(checkDateValidation()){
      downloadReport(searchParams) 
    } 
  }
  let handleClearFilter=()=>{ 
    resetSearch(searchParams)
    setSalesExecutiveName("")
    setFilteredSalesExecutiveOptions([])
    searchParams.order_status=''
    searchParams.customer_name=''
    searchParams.order_id=''
    searchParams.se_id=''
    searchParams.start_date=''
    searchParams.end_date='' 

  }
  
  useEffect(() => {
     if (Object.values(searchParams).some(param => param !== '')) {
      setShowserchbar(true);
    } else {
      setShowserchbar(false);
      resetSearch(searchParams);
    }
  },[searchParams])
    useEffect(() => {
    if (searchUserName) {
            searchusers(searchUserName)
                .then((response) => {
                    setUserData(response.data.data);
                    const userData = response.data.data;
                    const filteredUsers = userData.filter((user) =>
                      user.name.toLowerCase().includes(searchUserName.toLowerCase())
                    );
                    setFilteredSalesExecutiveOptions(filteredUsers.map((user) =>{ return {id:user.id,name:user.name}; }));
                })
                .catch((error) => {
                    console.error('Error occurred:', error);
                });
        }else{
              resetSearch(searchParams)
        }
  }, [searchUserName]);
 
  useEffect(  ()=>{
    async function getStatuses(){
    let result=await getOrderStatuses();
    if(result?.data?.status){
      setOrderStatus(result?.data?.data);
    }
  }

  getStatuses();
  },[])
  const handleUserNameChange = (selected) => {  
    setSalesExecutiveName(selected[0]?.name); 
    setSelectedDate(prevState=>{ 
      return {...prevState,["se_id"]:selected[0]?.id?selected[0]?.id:""}
    })
  };

  const handleUserInputChange = (inputValue) => {
    setSearchUserName(inputValue)
    if (inputValue.trim() == '') {
      setFilteredSalesExecutiveOptions([]);
    }  
  };
    const divStyles = {
        boxShadow: '1px 2px 9px #000',
        'marginBottom': '20px',
        padding: '1em',
      }; 
    return(
        <>
         <div style={divStyles}>
        <Row>
          <Col xl={12} lg={12}>
            <Row>
              <Col>
              <Form.Control type="text" placeholder="Order Id"
              name="order_id"
              value={searchParams.order_id} 
                onChange={handleDateChange} 
               />
              </Col>
              <Col>
              <Form.Control type="text" placeholder="Customer Name" 
               name="customer_name"
               value={searchParams.customer_name} 
               onChange={handleDateChange} /> 
              </Col>
              { atob(localStorage.getItem('rol'))!=3 &&
              <Col>
               <Typeahead
                  id="salesExecutiveName"
                  onChange={handleUserNameChange}
                  options={filteredSalesExecutiveOptions}
                  value={searchParams.salesExecutiveName} 
                  onInputChange={handleUserInputChange}
                  placeholder="Sales Executive "
                  labelKey="name"
                  selected={salesExecutiveName ? [salesExecutiveName] : []}
                  className='form-control border'
                  style={{ width: '100%', paddingLeft: 10, height: 39, fontSize: 14 }}
                  renderMenuItemChildren={(option) =>{return option?.name; }}
                  />
              </Col>}
              <Col>
              <Form.Control 
                name="start_date"
                type="date" 
                value={searchParams.start_date} 
                onChange={handleDateChange} 
              />
              </Col>
              <Col>
              <Form.Control  
              value={searchParams.end_date} 
              onChange={handleDateChange} 
              name="end_date" 
              type="date" placeholder="Start Date" />
              </Col>
              
              {(status!="DRAFT" && status!="SUBMIT")? 
                <Col><Form.Select aria-label="Status"
                name="order_status"
                value={searchParams.order_status} 
                  onChange={handleDateChange} >
                    <option>Status</option>
                    {
                      orderStatus.map(x=>{
                        return  <option value={x.id} key={x.id}>{x.name}</option>
                      })
                    } 
                  </Form.Select>
                  </Col>
                  :"" }
             
              {/* <Col>
              <Button variant="danger" 
                onClick={search}
                style={{   backgroundColor: '#FD1616', borderRadius: '30px'}}>
                      <Icon.Search color="black"
                        size={15} /> Search</Button>
              </Col> */}
               
            </Row>
            {showserchbar ? (
              <Row>
                <div  style={{    margin: '10px 0px 0px 0px',textAlign:"right"}}>
                <Button variant="danger" 
                  onClick={search}
                  style={{  width:'15%', backgroundColor: '#FD1616', borderRadius: '30px'}}>
                        <Icon.Search color="black"
                          size={15} /> Search</Button> &nbsp;
                <Button variant="danger" 
                  onClick={handleClearFilter}
                  style={{  width:'15%', backgroundColor: '#FD1616', borderRadius: '30px'}}>
                  <Icon.Search color="black"
                  size={15} /> Clear filters</Button> &nbsp;
                {showDownload &&(
                <Button variant="danger" 
                  onClick={reportDownload}
                  style={{ width:'15%', backgroundColor: '#FD1616', borderRadius: '30px'}}>
                        <Icon.Download color="black"
                          size={15} /> Download</Button> 
                )}
                </div>
            </Row>
            ) : null}
          </Col>
        </Row>

      </div></>
    )
}

export default Searchbar