import { getEnvUrl, getToken, axiosInstance } from "../../utils/Api";
import axios from 'axios';
export const getUserToken = () => {
  const tokenStr = localStorage.getItem("token");
  return tokenStr ? `Bearer ${tokenStr}` : null;
};

export const fetchDesignation = async () => {
    try {
        const response = await axiosInstance.get('/userType/getAllUserType',{
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Designation:', error);
        return false;
    }
};

export const fetchBranch = async () => {
    try {
        const response = await axiosInstance.get('/branch/getBranchdata',{
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Branch:', error);
        return false;
    }
};

export const fetchProducts = async () => {
    try {
        const response = await axiosInstance.get('/products/productPaginationData',{
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Products:', error);
        return false;
    }
};