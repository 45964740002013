import axios from "axios";

const axiosInstance=axios.create({
    headers : {
        "content-type":"application/json",
        Accept:"application/json"
    }
})
axiosInstance.interceptors.response.use(
  (response) => {
    if (!response.data?.status && response.data?.errorMessage === "Invalid token"||!response.data?.status && response.data?.errorMessage === "Permission denied") {
      localStorage.clear();  
      window.location.reload();

    }
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      localStorage.clear();  
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
 export const urlPath = window.location.host;  
 export const getEnvUrl = () => {  
  return process.env.REACT_APP_BASE_URL
};   
export const getToken = () => {
    return localStorage.getItem("token");
  };
  export const getUserName = () => {
   if(localStorage.getItem("udata")!=undefined){
    let udata=atob(localStorage.getItem("udata"))
    udata= JSON.parse(udata);
    return udata.uname;
   }else{
    return "User"
   }
   
  };

  export const getUserId = () => {
    if(localStorage.getItem("udata")!=undefined){
     let udata=atob(localStorage.getItem("udata"))
     udata= JSON.parse(udata);
     return udata.id;
    }else{
     return "User"
    }
    
   };
export  const UserAuth = ()=>{    
    if(localStorage.getItem("token")!=null){
       return true;
    }else{
       return false;
    }
} 
  axiosInstance.defaults.baseURL = getEnvUrl();
  export {axiosInstance};
