import React, { useEffect, useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchOrderDetailsById,orderStatusUpdate } from "../../services/Order/Order";
const Orderview = (props) => { 
  let { isShow, close,viewOrderId,status,resetTableRender }=props; 
  const handleShow = () => setShow(true);
  const handleClose = () => { setShow(false); close() };
  const [show, setShow] = useState(false);
  const [orderdetails, setOrderDetails] = useState(); 
  const [btnLabel, setBtnLabel] = useState([]);
  let orderID=viewOrderId
  const [comments, setComments] = useState(''); 
  const [dueDate, setDueDate] = useState(); 
  const [isDueDateExceeded, setIsDueDateExceeded] = useState(false);
  const handleTextareaChange = (event) => {
    setComments(event.target.value);
  };
 let statusUpdate=async (status,orderId)=>{ 
    const response = await orderStatusUpdate({orderId,status,comments});    
    if(response.status){
      toast.success(response.message)
      resetTableRender()
      close();
    }else {
      toast.error(response.message); 
      resetTableRender()
      close();
    }
  }
  useEffect(() => {
    const dueDateObject = new Date(dueDate);
    const currentDate = new Date();
    
    const dueDateOnly = new Date(dueDateObject.getFullYear(), dueDateObject.getMonth(), dueDateObject.getDate());
    const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    if ((dueDateOnly > currentDateOnly) && (orderdetails?.status < 3)) {
        setIsDueDateExceeded(false);
    } else if ((dueDateOnly < currentDateOnly) && (orderdetails?.status >= 3)) {
        setIsDueDateExceeded(false);
    } else if ((dueDateOnly < currentDateOnly) && (orderdetails?.status < 3)) {
        setIsDueDateExceeded(true);
    } else {
        setIsDueDateExceeded(false);
    }
  }, [dueDate]);

  useEffect(() => {
    setShow(isShow)
    const fetchOrderDetails = async () => {
      try {
        const response = await fetchOrderDetailsById(orderID);
        setOrderDetails(response);
        setDueDate(response?.dueDate)
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    fetchOrderDetails();
  }, [orderID]);
  if (orderdetails) {
    const role = atob(localStorage.getItem('rol'));
    let btnLable = [];
switch(role) {
   case '1':
        switch(orderdetails?.status) {
           case 1: 
                btnLable=[{'buttonLable':"SUBMIT",'status':2},{'buttonLable':"REJECT",'status':4}]
                break;                   
            case 2: 
                btnLable=[{'buttonLable':"APPROVE",'status':3},{'buttonLable':"REJECT",'status':4}]
                break;
            case 3: 
                btnLable=[{'buttonLable':"SHIPPED",'status':5},{'buttonLable':"CANCELLED",'status':7}]
                break;
            case 5: 
                btnLable=[{'buttonLable':"DELIVERED",'status':6},{'buttonLable':"CANCELLED",'status':7}]
                break;
            case 6: 
                btnLable=[{'buttonLable':"RETURNED",'status':8},{'buttonLable':"CANCELLED",'status':7}]
                break;
            default: 
                btnLable=[]; 
        }
        break;
    case '3':
        switch(orderdetails?.status) {
            case 1: 
                btnLable=[{'buttonLable':"SUBMIT",'status':2},{'buttonLable':"REJECT",'status':4}]
                break;
            default: 
                btnLable=[]; 
        }
        break;
    case '2':
        switch(orderdetails?.status) {
          case 1: 
                btnLable=[{'buttonLable':"SUBMIT",'status':2},{'buttonLable':"REJECT",'status':4}]
                break;
            case 2: 
                btnLable=[{'buttonLable':"APPROVE",'status':3},{'buttonLable':"REJECT",'status':4}]
                break;
            default: 
                btnLable=[]; 
        }
        break;
    case '4':
        switch(orderdetails?.status) {
            case 3: 
                btnLable=[{'buttonLable':"SHIPPED",'status':5},{'buttonLable':"CANCELLED",'status':7}]
                break;
            case 5: 
                btnLable=[{'buttonLable':"DELIVERED",'status':6},{'buttonLable':"CANCELLED",'status':7}]
                break;
            case 6: 
                btnLable=[{'buttonLable':"RETURNED",'status':8},{'buttonLable':"CANCELLED",'status':7}]
                break;
            default: 
                btnLable=[]; 
        }
        break;
    default: 
        btnLable=[]; 
}

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-xl">
      <Modal.Header closeButton>
        <h4><Icon.CartCheckFill/></h4>
        <h5 className="modal-title">Order Form</h5>
      </Modal.Header>
      <Modal.Body>
        <form className="row g-3 needs-validation" id="viewOrderForm">
          <div className="col-7 px-3">
            <p><b>Sold By : Rolloverstock</b><br/>
            5, 20th Main Rd, KHB Colony,
            7th Block, Koramangala
            Bengaluru, <br/>Karnataka - 560095 
           <br/> GST Registration No:27ANBPA0923C1ZV</p> 
            <td>
              <tr><b>Place of Supply : </b> {orderdetails?.sos?.state_name} </tr>
              <tr><b>Place of Delivery : </b> {orderdetails?.sod?.state_name} </tr>
              <tr><b>Order Number : </b> {orderdetails?.orderId }</tr>
              <tr><b>Order Date : </b> {orderdetails?.orderDate }</tr>
              <tr><b>Due Date : </b> {orderdetails?.dueDate }</tr>
              <tr><b>Order CreatedBy : </b>{orderdetails?.users.name } </tr>
              {/* <tr><b>Order Status : </b>{orderdetails?.status} </tr> */}
            </td>
          </div>
          <div className="col-4 px-3">
            <p><b>Billing Address : </b> 
              {orderdetails?.billing_address} - {orderdetails?.billing_address_pincode}</p>

            {/* <div className="col-4 px-3"> */}
            <p><b>Shipping Address : </b>
               {orderdetails?.shipping_address} - {orderdetails?.shipping_address_pincode}</p>
            
            <p><b>Landmark : </b>
               {orderdetails?.landmark}</p>
            {/* <b>State Code:4</b> */}
          </div>
           <div className="container">
              <div className="row">
                  <div className="col-12">
                      <table className="table table-bordered mt-2">
                          <thead>
                              <tr>
                                  <th style={{textAlign: 'center'}}>Product-ID</th>
                                  <th style={{textAlign: 'center'}}>Peoduct Name</th>
                                  <th style={{textAlign: 'center'}}>Quantity</th>
                                  {/* <th style={{textAlign: 'center'}}>Unit Type</th> */}
                                  <th style={{textAlign: 'center'}}>Unit Price</th>
                                  {/* <th style={{textAlign: 'center'}}>Discount %</th>
                                  <th style={{textAlign: 'center'}}>Discount Amount</th> */}
                                  <th style={{textAlign: 'center'}}>Total</th>
                              </tr>
                          </thead>
                          <tbody>
                              {orderdetails?.order_items.map((item, index) => (
                                  <tr key={index}>
                                      <td key={index+1} style={{textAlign: 'center'}}>{item.product_id}</td>
                                      <td key={index+2} style={{textAlign: 'center'}}>{item.products.name}</td>
                                      <td style={{textAlign: 'center'}}>{item.quantity}</td>
                                      {/* <td style={{textAlign: 'center'}}>{item.unit_type}</td> */}
                                      <td key={index+3}  style={{textAlign: 'center'}}>{item.unit_price}</td>
                                      {/* <td key={index+4}  style={{textAlign: 'center'}}>{item.discount}%</td>
                                      <td key={index+5} style={{textAlign: 'center'}}>{item.discount_amount}</td> */}
                                      <td key={index+6} style={{textAlign: 'center'}}>{item.total_price}</td>
                                  </tr>
                              ))}
                          </tbody>
                      </table>  
                       <div style={{textAlign: 'right'}}>
                        <p><b>Total Order Amount:</b> {orderdetails?.orderAmount}</p>
                      </div>
                  </div>
              </div>
          </div>

          {orderdetails?.orders_version.length>0?<>
                <strong><u>Order History</u></strong>
                      <table className="table table-bordered mt-2">
                          <thead>
                              <tr>
                                  <th style={{textAlign: 'center'}}>Updated by</th>
                                  <th style={{textAlign: 'center'}}>Comments</th> 
                              </tr>
                          </thead>
                          <tbody>
                              {orderdetails?.orders_version.map((comment, index) => (
                                  <tr key={index}>
                                      <td key={index+1} style={{textAlign: 'center'}}>{comment.update_string}</td>
                                      <td key={index+2} style={{textAlign: 'center'}}>{comment.comments}</td>
                                    </tr>
                              ))}
                          </tbody>
                      </table>
              </>:''}


         
          {btnLable.length ? <div className="col-5 px-3"> <textarea
              className="form-control"
              placeholder="Comments/Notes..."
              rows="4"
              value={comments}
              onChange={handleTextareaChange}
            ></textarea> </div>:''}
         
          {btnLable.length ? 
            <div className="modal-footer" >
               {/* <Button variant="danger approvebtn" onClick={() => { handleClose(); }} > Approve</Button> */}

              {(isDueDateExceeded)? (
              <p style={{ color: 'red' }}>Due date exceeded!</p>):(
              <Button variant="danger approvebtn" onClick={() => { statusUpdate(btnLable[0].status,orderdetails?.orderId); }} >{btnLable[0].buttonLable}</Button>)}
           {orderdetails?.status !== 6 ? (
            <Button
              variant="danger rejectbtn"
              onClick={() => {
                statusUpdate(btnLable[1].status, orderdetails?.orderId);
              }}
            >
              {btnLable[1].buttonLable}
            </Button>
          ) : null}</div>:''
          } 
        </form>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
} 
};

export default Orderview;
