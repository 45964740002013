import React,{useState,useEffect, Profiler} from 'react';
import { useNavigate } from 'react-router-dom'
import { Navbar, Nav } from "react-bootstrap";
import { LuMenuSquare } from "react-icons/lu";
import { RiNotification3Line, RiSettings3Line, RiQuestionLine } from "react-icons/ri"
import './Header.css'
import { LuLayoutGrid } from 'react-icons/lu';
import { getEnvUrl, getToken,getUserName } from "../../utils/Api";
import { getUserProfilePic } from '../../services/User/User';

function Header({ isNavVisible, setIsNavVisible }) {
  let [profilepic,setProfilePic]=useState(`${getEnvUrl()}/users/getProfilePic/${getToken()}`) 
  useEffect(()=>{ 
    getUserProfilePic().then((response)=>{
      setProfilePic(response);
    })
  },[])
  const navigate = useNavigate();

  const handleClick = () => { 
    navigate('/createneworder');
  };
  return (
    <div className="topnav p-1 mb-2" md={12} sm={12}>
      <Navbar
        expand="lg"
      >
         <div className="d-flex justify-content-between w-100 px-3">
      <div>
        <button className="nav-icon-btn menu-toggle" onClick={() => setIsNavVisible(!isNavVisible)}>
          <LuMenuSquare className="nav-icon" />
        </button>
      </div>
       
      <div>
        {
         atob(localStorage.getItem('rol'))!=4 && <button onClick={handleClick} type="button" className="btn-primary create-order-btn btn-sm custom-button " >
         <span className="icon"><LuLayoutGrid /></span> Create New Order
       </button>
        }
         {/* 
        <button className="nav-icon-btn">
          <RiQuestionLine className="nav-icon mx-2" />
        </button>
        <button className="nav-icon-btn">
          <RiSettings3Line className="nav-icon mx-2" />
        </button>
        <button className="nav-icon-btn">
          <RiNotification3Line className="nav-icon mx-2" />
        </button> */}
       
            <span className="profile-name" onClick={()=>{navigate('/settings');}}><img src={profilepic} alt="Profile Picture" className="profile-pic" />{getUserName()}</span>
      </div>
    </div>
      </Navbar>
    </div>
  )
}

export default Header