import { data } from "jquery";
import { getEnvUrl, getToken, axiosInstance } from "../../utils/Api";
import { getUserToken } from "../User/User";
import axios from 'axios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const AddProduct = async (inputData) => {
    try {
        const response = await axiosInstance.post('/products/createProduct', inputData, {
            headers: {
                Authorization: getUserToken(),
            },
        });

        if (response && response.data && response.data.status === true) {
            return response;
        } else {
            toast.error(response.data.errorMessage);
            return false;
        }
    } catch (error) {
        console.error('Error in Add Product:', error);
        return false;
    }
};

export async function getProductDataByID(editUserId) {
    try {
        const response = await axiosInstance.get('/products/productData', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { productId: editUserId },
        });

        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Error fetching data:', response.status, response.statusText);
            throw new Error(`Error fetching data: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export const updateProduct = async (updatedData) => {
    try {
        const response = await axiosInstance.put('/products/updateProduct', updatedData, {
            headers: {
                Authorization: getUserToken(),
            },
        });

        if (response.data.status === true) {
            return response;
        }
        return toast.error(response.data.errorMessage);
    } catch (error) {
        console.error('Error in Edit Product:', error);
        return false;
    }
};

export const deleteProductById = async (deleteCustomerId) => {
    try {
        const response = await axiosInstance.delete('/products/updateProductStockStatus', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { productId: deleteCustomerId },
        });
        return response.data;
    } catch (error) {
        console.error('Error in Delete Product By ID:', error);
        return false;
    }
};