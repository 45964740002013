import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import Loggedview from './LoggedView';
import ErrorComponent from './components/errorComponent/ErrorComponent';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/settings'; 
import Login from './pages/login/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateNewOrder from './pages/createNewOrder/createNewOrder';   
import EditOrder from './pages/editOrder/EditOrder';
import PendingRequests from './pages/pendingRequests/PendingRequests';  
import DraftRequests from './pages/DraftRequests/DraftRequests';  
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customermaster from './pages/customerMaster/customerMaster';
import Orders from './pages/orders/Orders';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import Usersmaster from './pages/employeeMaster/usersMaster';
import Outletmaster from './pages/outletMaster/outletMaster';
import Productmaster from './pages/productMaster/productMaster';
const router  = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
   {
    path: "/privacy-policy-statement",
    element: (
      <div className='privacy'>
        <iframe src="/privacyPolicy.html" title="Privacy Policy" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }} />
      </div>
    )
    },
    {
    element: <Loggedview />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/settings",
        element: <Settings />, 
      },
      {
        path: "/createneworder",
        element: <CreateNewOrder />,
      },  
      {
        path: "/editorder/:editOrderId",
        element: <EditOrder />,
      },  
      {
        path: "/customermaster",
        element: <Customermaster />,
      },
      {
        path: "/usersmaster",
        element: <Usersmaster />,
      },
      {
        path: "/outletsmaster",
        element: <Outletmaster />,
      },
      {
        path: "/pendingrequests",
        element: <PendingRequests />,
      },
      {
        path: "/draftrequests",
        element: <DraftRequests />,
      },
      
      {
        path: "/orders",
        element: <Orders />,
      },
      {
        path: "/productsmaster",
        element: <Productmaster />,
      }
    ],
    errorElement: <ErrorComponent />,
  }
]);

function App() {
  return (
    <>
    <div className="App">
      <RouterProvider router={router} /> 
    </div>
    <ToastContainer  position="bottom-right"   />
    </>
    
  );
}

export default App;
