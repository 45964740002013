import React, { useEffect, useState,useRef } from 'react';
import { useNavigate  } from 'react-router-dom'; 
import './settings.css'; 
import { getUserData, getUserProfilePic, uploadProfileData, updatePasswordData } from '../../services/User/User';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { toast, ToastContainer } from "react-toastify";
import Form from 'react-bootstrap/Form';
import {UserLogout} from '../../services/User/User'
import { styled } from "@mui/material/styles";
  const RequiredAsterisk = styled("span")({
    color: "red",
  });
function Settings() {
  
  const [fileName, setFileName] = useState("No file chosen");
  const [userData, setUserData] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [loading, setLoading] = useState(true);
  const [passwordActive, setPasswordActive] = useState(false);
  const [passwords, setPasswords] = useState({
    currentPassword:'',
    newPassword: '',
    confirmPassword: '',
  });
 const [showPassword, setShowPassword] = useState(false);
 const [showCurrentPassword, setShowCurrentPassword] = useState(false);
 const [showConfPassword, setShowConfPassword] = useState(false);
 const [passwordType, setPasswordType] = useState(true);
 const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=]).{6,}$/;

    
  const handlePasswordToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handlePasswordToggleConf = () => {
    setShowConfPassword((prevShowConfPassword) => !prevShowConfPassword);
  };
  const handlePasswordToggleCurrent = () => {
    setShowCurrentPassword((prevShowCurrPassword) => !prevShowCurrPassword);
  };

  const handlePasswordUpdate = async () => {
  try {
       setLoading(true)
    if (passwords.currentPassword.trim() === '' ) {
      toast.error('Please enter current password'); 
      setTimeout(() => {
         setLoading(false)
      }, 3000);
      return;
    }
    if (passwords.newPassword.trim() === '' && passwords.confirmPassword.trim() === '') {
      toast.error('Please enter new password & confirm password'); 
      setTimeout(() => {
         setLoading(false)
      }, 3000);
      return;
    } 
   
    if (passwords.newPassword !== passwords.confirmPassword) {
      toast.error('Passwords do not match'); 
      setTimeout(() => {
         setLoading(false)
      }, 3000);
      return;
    } 
    const response = await updatePasswordData({
      currentPassword:passwords.currentPassword,
      newPassword: passwords.newPassword,
      confirmPassword: passwords.confirmPassword
    }); 
    if (response && response.data && response.data.status === true) {
       toast.success('Password updated successfully. You need to log in.' ); 
       setPasswords({
        currentPassword:'',
        newPassword: '',
        confirmPassword: '',
      });
      setTimeout(() => {
        UserLogout()
        localStorage.clear();
        navigate('/login');
        setLoading(false)
      }, 3000);

      setShowCurrentPassword(false)
      setShowPassword(false)
      setShowConfPassword(false)
    }else{
      setLoading(false)
    }
   
  } catch (error) {
    toast.error('Error updating password:', error.message); 
  }
}

  const navigate = useNavigate();

    const fetchData = async () => {
      try {
        const user = await getUserData();
        const filteredUserData = {
          name: user.data.name,
          email: user.data.email,
          phone_number: user.data.phone_number,
          user_typeid: user.data.user_typeid,
          // profilePic: user.data.profilePic,
          branch:user.data.branchid.branch_name,
          reporting:user.data.reportingUser.name
        }; 
        setUserData(filteredUserData); 
         try {
                const profilePicUrl = await getUserProfilePic();
                setProfilePic(profilePicUrl);
            } catch (error) {
                // toast.error('Error fetching profile picture:', error);
            } finally {
                setLoading(false); 
            }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

  useEffect(() => {
    fetchData();
  }, []);
  

 function handleUpload(event) {
  event.preventDefault(); 
  const fileInput = document.getElementById('image');
  const file = fileInput.files[0];

  if (!file) {
    return;
  }

  const formData = new FormData();
  formData.append('profilePic', file);

  uploadProfileData(formData)
    .then(response => {
      if (response) {
        toast.success('Profile Picture updated successfully', response);
        fetchData(); 
      }
      fileInput.value = ''; 
      setFileName("No file chosen");
    })
    .catch(error => {
      // toast.error('Error uploading profile data', error);
    });
  } 
let [settingsList,SetSettingsList]=useState([
    { id: 1, title: 'Personal Information', subInfo: '',active:true },
    { id: 2, title: 'Password Setting',active:false },
  ])

 
  const handleSettingClick = (title) => {
    let obj=settingsList.map((x)=>{ 
        x.active=x.title==title?true:false 
        return x;
      })
      SetSettingsList(obj)

    if (title === 'Password Setting') {
      setPasswordActive(true) 
    }else{
      setPasswordActive(false)
    } 
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  return (
    <div> 
      <div class="pagetitle"><h1>Settings</h1><nav></nav></div>
    <form action="/upload" method="post" encType="multipart/form-data">
      <div className="container mt-4">
        <div className="d-flex flex-column flex-md-row">
          <div className="menu-container mb-3 mb-md-0 mr-md-3">
            {settingsList.map((setting) => (
              <SettingsBox   active={setting.active} key={setting.id} title={setting.title} subInfo={setting?.subInfo}  onClick={() => handleSettingClick(setting.title)} />
            ))}
          </div>
          {!passwordActive&&(
          <div className="extra-box border p-3 ml-md-3 box">
            <div className="d-flex flex-column flex-md-row align-items-center">
             <div className="mb-3 mb-md-0 mr-md-3">
                {loading ? (
                    null
                     ) : (
                    <img
                        src={profilePic}
                        alt="Profile"
                        className="rounded-profile-picture"
                    />
                )}
             </div>
               <div className="upload">
                {/* <input type="file" id="image" name="image" accept="image/*" /> */}

                 
      <input
        type="file"
        id="image" 
        name="image" 
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          setFileName(e.target.files[0].name) 
        }}
      />
      <button onClick={handleButtonClick} className="custom-file-upload">
        Choose File
      </button>
      <span className='filelable'>{fileName}</span> 

                <button type="button" className="insert" onClick={handleUpload}>
                  Upload
                </button>
               </div>
            </div>
            <div className="mt-3 d-flex flex-column flex-md-row">
              <NonInputBox title="Name" value={userData ? userData.name : ''} />
              <NonInputBox title="Email-ID" value={userData ? userData.email : ''} />
            </div>
            <div className="mt-3 d-flex flex-column flex-md-row">
              <NonInputBox title="Mobile Number" value={userData ? userData.phone_number : ''} />
              <NonInputBox
                title="Role"
                value={userData && userData.user_typeid ? userData.user_typeid.name : ''}
              />
            </div>
            <div className="mt-3 d-flex flex-column flex-md-row">
              <NonInputBox title="Branch" value={userData ? userData.branch : ''} />
              <NonInputBox
                title="Reporting To"
                value={userData && userData.reporting ? userData.reporting : ''}
              />
            </div>
            <div className="mt-4">
              {/* <button className="save">Save</button> */}
             </div>
           </div>  
          )}
          {passwordActive&&(
         <div className="extra-box border p-3 ml-md-3 box">
              <div className="row align-items-center">
                <div className="col-md-3 text-md-end">
                  <label htmlFor="currentPassword" className="mr-md-3">Current Password: </label>
                </div>
                <div className="col-md-8">
                  <input
                      type="text"
                      id="currentPassword"
                      placeholder="Current Password"
                      className={`custom-input ${
                        showCurrentPassword || passwords.currentPassword === '' ? '' : 'password-hidden'
                       }`}
                       value={passwords.currentPassword}
                      onChange={(e) => setPasswords({ ...passwords, currentPassword: e.target.value })}
                  />
                  <button type="button" className="btn btn-link p-0" onClick={handlePasswordToggleCurrent}>
                    {showCurrentPassword ? <VscEyeClosed /> : <VscEye />}
                  </button>
                </div>
              </div>
              <div className="row mt-3 align-items-center">
                <div className="col-md-3 text-md-end">
                  <label htmlFor="newPassword" className="mr-md-3">New Password: </label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="newPassword"
                    placeholder="New Password"
                    className={`custom-input ${
                        showPassword || passwords.newPassword === '' ? '' : 'password-hidden'
                       }`}
                    value={passwords.newPassword}
                    onChange={(e) => setPasswords({ ...passwords, newPassword: e.target.value })}
                  />
                  <button type="button" className="btn btn-link p-0" onClick={handlePasswordToggle}>
                    {showPassword ? <VscEyeClosed /> : <VscEye />}
                  </button>
                </div>
              </div>
              <div className="row  align-items-center">
                <div className="col-md-3 text-md-end">
                  <label htmlFor="confirmPassword" className="mr-md-3">Confirm Password:</label>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    className={`custom-input ${
                        showConfPassword || passwords.confirmPassword === '' ? '' : 'password-hidden'
                       }`}
                    value={passwords.confirmPassword}
                    onChange={(e) => setPasswords({ ...passwords, confirmPassword: e.target.value })}
                  />
                  <button type="button" className="btn btn-link p-0" onClick={handlePasswordToggleConf}>
                    {showConfPassword ? <VscEyeClosed /> : <VscEye />}
                  </button>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 offset-md-3">
                  <button type="button" className="save" disabled={loading} onClick={handlePasswordUpdate}>Save</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
    </div>
  );
}

function SettingsBox({ title, subInfo, onClick,active }) {
  return (
    <div className={active?'box mt-3 p-3 border cursor activeSubMenu':'box mt-3 p-3 border cursor'} onClick={onClick}>
      <b>{title}</b>
      {subInfo && <p>{subInfo}</p>}
    </div>
  );
}

function NonInputBox({ title, value }) {
  return (
    <div className="non-input-box mb-3 mb-md-0 mr-md-3 border">
      <div className="box-content">
        <strong>{title}: </strong> {value}
      </div>
    </div>
  );
}

export default Settings;
