import React, { useState } from 'react';
import ROSLogo from '../../assets/ROS_Logo.png'; 
import { FaUser } from "react-icons/fa";
import { UserLogin } from '../../services/User/User';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { IoMdLock } from "react-icons/io";
import { Spinner } from 'react-bootstrap';
import { ToastContainer,toast } from "react-toastify";
import { forgotPassword } from '../../services/User/User';
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import {UserAuth} from "../../utils/Api"
async function handleLogined(email, password) {
    const response = await UserLogin(email, password); 
      
    if (response && response.status === 200) {
        const data = response.data; 
        if (data && data.status === true && data.data) {
            const { token, rol,udata } = data.data;
            localStorage.setItem('token', token);
            localStorage.setItem('rol', rol);
            localStorage.setItem('udata', udata);
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function Login() {
    const [email, setEmail]                 = useState('');
    const [password, setPassword]           = useState('');
    const [passwordType, setPasswordType]   = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [loading, setLoading]         = useState(false);
    const [isDisabled, setIsDisabled]   = useState(false);  
    const handleForgotPasswordClick     = () => { setShowResetPassword(true); };
    const handleGoBackClick             = () => {
                                                setShowResetPassword(false);
                                                setPassword("")
                                                setIsDisabled(false)
                                            }; 
    const handleEmailChange             = (e) => { setEmail(e.target.value); };
    const handlePasswordChange          = (e) => { setPassword(e.target.value); };
    
    const handleLogin = async (event) => {
        setIsDisabled(true);
        event.preventDefault();
        if (showResetPassword) {
            handleResetPassword(email);
        } else {
            if (validateInputs()) {
            const loggedIn = await handleLogined(email, password);
            if (loggedIn) {
                const role = atob(localStorage.getItem('rol'));
                if (role!=4) {
                    window.location.href = "/dashboard";
                }else{
                    window.location.href = "/orders";
                }
            } else {
                toast.error('Invalid username or password');
                setTimeout(() => {
                setIsDisabled(false)
                }, 3000);
            }
           }
        }
    };

    const handleResetPassword = async (resetEmail) => {
        try {
            if (validateInputs()) {
                setLoading(true);
            const res = await forgotPassword(resetEmail);
            if (res && res.data && res.data.status === false) {
                toast.error(res.data.errorMessage);
                setPassword("")
            } else {
                setLoading(true);
                toast.success("New password has been sent to user mail-ID..!");
                setPassword("")
                setShowResetPassword(false);
            }
            setIsDisabled(false)
            }
        } catch (error) {
            console.log('Error resetting password:', error);
        } finally {
            setLoading(false);
        }
    };

    const validateInputs = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       if (!emailPattern.test(email)) {
        toast.error('Please enter a valid email.');
        setTimeout(() => {
            setIsDisabled(false)
        }, 3000);
        return false;
        }
        return true
        // const passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$*-.,;]).{8,16}$/;
        // if (password.length < 8 || !passwordPattern.test(password)) {
        //     toast.error('Enter valid password');
        //     return false;
        // }
    };

    return !UserAuth()? (
        <div className="row d-flex justify-content-center align-items-center login-container" style={{ margin: 0, padding: 0 }}>
           
            <div className="row text-black p-0 h-100">
                <div className="col-lg-6">
                    <div className="card-body p-md-5 mx-md-4">
                        <div className="text-center">
                            <img src={ROSLogo} style={{ width: '144px' }} alt="logo" />
                            <h4 className="mt-4 mb-4 pb-1 login-title">{!showResetPassword?"Login":"Enter Registered Email-ID "}</h4>
                        </div>

                        <div className="col">
                            <div className="mb-3 border rounded">
                                <div className="d-flex align-items-center px-2 py-1">
                                    <FaUser />
                                    <input type="email" className="ps-3 w-100 login-input" style={{ border: 'none' }} placeholder="Email" value={email} onChange={handleEmailChange} />
                                </div>
                            </div>

                            <div className={`mb-4 border rounded ${showResetPassword ? 'd-none' : ''}`}>
                                <div className="d-flex align-items-center px-2 py-1">
                                    <IoMdLock />
                                        <input
                                            type="text"
                                            className={`ps-3 w-100 login-input  ${
                                                passwordType || password == '' ? '' : 'password-hidden'
                                            }`}
                                            style={{ border: 'none' }}
                                            placeholder="Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                        />                
                                        <button className="login-password-visibility" onClick={() => setPasswordType(!passwordType)}>
                                        {passwordType ? <VscEye style={{ width: 24 }} /> : <VscEyeClosed style={{ width: 24 }} />}
                                    </button>
                                </div>
                            </div>
                            {loading && (
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="custom-spinner"></div>
                                </div>
                            )}
                            <div className={`text-center pt-1 mb-5 pb-1 ${showResetPassword ? 'd-none' : ''}`}>
                                <button className="fa-lg mb-3 login-button px-4 py-1" type="button" disabled={isDisabled} onClick={handleLogin}>{showResetPassword ? 'Reset Password' : 'Login'}</button>
                                <a className={`forgot-password-link fa-lg mb-3 px-4 py-4 ${isDisabled ? 'true' : ''}`}
                                   onClick={handleForgotPasswordClick}
                                   style={{ pointerEvents: isDisabled ? 'none' : 'auto', cursor: isDisabled ? 'not-allowed' : 'pointer', opacity: loading ? 0.5 : 1 }}
                                > Forgot Password?</a>  
                            </div>
                            <div className={`text-center pt-1 mb-5 pb-1 ${showResetPassword ? '' : 'd-none'}`}>
                                <button className="fa-lg mb-3 login-button px-4 py-1" type="button" onClick={handleLogin} disabled={loading} style={{ marginRight: '10px' }}>Reset Password</button>
                                <button className="fa-lg mb-3 login-button px-4 py-1" type="button" onClick={handleGoBackClick} disabled={loading} style={{ marginRight: '35px' }}>User Login</button>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="d-none d-lg-block d-xl-block col-lg-6 d-flex align-items-center login-image-section">
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                        <h4 className="mb-4">Sample Image</h4>
                    </div>
                </div>
            </div>
        </div>
    ):window.location.replace("/");
}

export default Login;
