import React, { useState,useEffect } from 'react';
import { Container, Nav, Image } from 'react-bootstrap';
import ROSLogo from '../../assets/ROS_Logo.png';
import { LuLayoutGrid,LuStickyNote,LuShoppingCart,LuSettings,LuCircleDot,LuUsers } from "react-icons/lu";
import { BiLogOut } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { TiDocument } from "react-icons/ti";
import { Link,useLocation } from "react-router-dom";
import './SideBar.css';
import * as Icon from 'react-bootstrap-icons'; 
import {UserLogout} from '../../services/User/User'
import { useNavigate } from 'react-router-dom'

function SideBar({ setIsNavVisible, isMobileView }) {
    const navigate = useNavigate();
    const logout=()=>{ 
        if(window.confirm('Are you sure you want to logout?')){ 
            UserLogout().then((res)=>{ 
               if(res.status){
                    localStorage.clear();
                    navigate('/login');
                }else{
                    alert(res.message)
                }
                
            }) 
        } 
    }
    const location = useLocation();
    const [userRoles, setUserRoles]=useState()
    let [changeDropDown,setChangeDropDown]=useState(false); 
    var menu = [
        {url:'/dashboard',classname:'nav-link',title:'Dashboard',icon:<LuLayoutGrid />,role:[1,2,3]}, 
        {url:'/draftrequests',classname:'nav-link',title:'Draft Requests',icon:<TiDocument />,role:[1,2,3]}, 
        {url:'/pendingrequests',classname:'nav-link',title:'Pending Requests',icon:<TiDocument />,role:[1,2,3]}, 
        {url:'/orders',classname:'nav-link',title:'Orders',icon: <LuShoppingCart />,role:[1,2,3,4]}, 
        {url:'/settings',classname:'nav-link',title:'Settings',icon:<LuSettings />,role:[1,2,3,4]}, 
        {url:'/customermaster',classname:'nav-link',title:'Customer Master',icon:<LuUsers />,role:[1]},
        {url:'/usersmaster',classname:'nav-link',title:'User Master',icon:<LuUsers />,role:[1]},
        {url:'/outletsmaster',classname:'nav-link',title:'Outlet Master',icon:<Icon.ShopWindow />,role:[1]},
        {url:'/productsmaster',classname:'nav-link',title:'Product Master',icon:<Icon.ShopWindow />,role:[1]},

      ];
      let aclCheck=(roles)=>{  
        return ((roles.indexOf(parseInt(atob(localStorage.getItem('rol'))))) !=-1)?true:false; 
     }

      let [dropdowns,setDropdowns]=useState(menu);
      useEffect(() => {
        const updatedDropdowns = dropdowns.map(data => {
            if (location.pathname === data.url) {
            return { ...data, classname: 'activeMenu nav-link' };
            } else {
            return { ...data, classname: 'nav-link' };
            }
        });
        setUserRoles(parseInt(atob(localStorage.getItem('rol'))))
        setDropdowns(updatedDropdowns);
        }, [location.pathname]);
    // useEffect(() => {
    //     //  setDropdowns(dropDownActive());  
    // }, [changeDropDown]);
    // let menuRerander=()=>{  
    //     setChangeDropDown(!changeDropDown)
    // }
 
    if (isMobileView) {
        return (
            <div className="d-flex flex-column mobile-navbar p-4">  
                <button className="mobile-nav-close" onClick={() => setIsNavVisible(false)}>      
                    <IoCloseSharp />
                </button>           
                <div className="d-flex flex-column justify-content-between h-100">
                    <Nav className="flex-column">     
                        <button  className="mt-2">
                            <div className="d-flex">
                                <img src={ROSLogo} className="w-50 pb-4" />
                            </div>
                        </button>
                        {dropdowns.map((dropdown, index) => { 
                        return ( 
                            <Link  onClick={()=>{setChangeDropDown(!changeDropDown)}}  to={dropdown.url} className={dropdown.classname}>
                            <div >
                            {dropdown.icon}  
                                <span className="menu-link">{dropdown.title}</span>
                            </div>
                        </Link>
                        );
                        })}
                    </Nav>
                    <div>
                        <div className="pt-3">
                            <BiLogOut />
                            <span className="menu-link" onClick={logout}>Logout</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="p-3 sidenav-container" style={{height:'auto'}}>
            <div className="d-flex flex-column justify-content-between h-100">
                <Nav className="flex-column">
                    <Link to={userRoles !== 4 ? "/dashboard" : "/orders"} className="mt-2">
                        <div className="d-flex">
                            <img src={ROSLogo} className="pt-4 pb-3" style={{ width: '100%' }} />
                        </div>
                    </Link> 
                    {dropdowns.map((dropdown, index) => { 
                        if(aclCheck(dropdown.role)){
                            return ( 
                                <Link key={index} onClick={()=>{setChangeDropDown(!changeDropDown)}} to={dropdown.url} className={dropdown.classname}>
                                <div >
                                {dropdown.icon}  
                                    <span className="menu-link">{dropdown.title}</span>
                                </div>
                            </Link>
                            );
                        } 
                    })} 
                </Nav>
                <div>
                    <div className="pt-3 cursor  d-flex align-items-center"> 
                        <BiLogOut />
                        <span className="menu-link" onClick={logout}>Logout</span>
                    </div>
              </div>
            </div>
        </div>
    )
}

export default SideBar