import React, { useEffect, useState,useRef } from 'react'; 
import Chart from 'chart.js/auto';
import './Dashboard.css'
import {LuIndianRupee } from "react-icons/lu";
import { orderDashboard } from "../../services/Order/Order";

import Orderview from "../common/Orderview";

function Dashboard() {
  const [OrderviewModal, setOrderviewModal] = useState(false);
  const [viewOrderId, setviewOrderId] = useState(false); 
  const [tableRender, setTableRender] = useState(false);  
  const [daySales, setDaySales] = useState('0');
  const [monthSales, setMonthSales] = useState('0');
  const [yearSales, setYearSales] = useState('0');
  const [recentOrder, setRecentOrder] = useState([]);
  let OrderviewModalClose = () => {
    setOrderviewModal(false)
  }
  let orderView = (id) => {
    setviewOrderId(id)
    setOrderviewModal(true)
  }
  const formatDecimal = (value) => Math.round(value);
  const fetchData = async () => {
    let response= await orderDashboard();
    if(response.status){
      setDaySales(formatDecimal(response.data?.day_sales))
      setMonthSales(formatDecimal(response.data?.month_sales))
      setYearSales(formatDecimal(response.data?.year_sales))
      barChartRebuild(response.data?.sales_by_month);
      pieChartRebuild(response.data?.outletOrders?.labels,response.data?.outletOrders?.values); 
      setRecentOrder(response.data?.rs_data)
    }     
  }; 
  useEffect(() => { 
    fetchData();
  }, [tableRender]);
  
  const barchart = useRef(null); // Reference to the canvas element
  const piechart = useRef(null); // Reference to the Chart instance

  const barChartRebuild = async (data) => {
    if(barchart.current){
      await barchart.current.destroy();
      salebarchart(data);
     }else{
      salebarchart(data);
     }  
    
  };

  const pieChartRebuild = async (labels,values) => {   
   if(piechart.current){  
    await piechart.current.destroy();  
   await subscriptionchart(labels,values);
   }else{ 
    subscriptionchart(labels,values);
   }
    
  };

  const salebarchart =async (inputdata) => { 
    barchart.current = new Chart(document.querySelector('#barChart'), {
      type: 'bar',
      data: {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ],
        datasets: [
          {
            label: 'Bar Chart',
            data: inputdata,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)',
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(201, 203, 207, 0.2)'
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)',
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  };

  const subscriptionchart = async (labels, values) => { 
    piechart.current = new Chart(document.querySelector('#pieChart'), {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Order Count',
            data: values,
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)',
              'rgb(172, 59, 201)',
              'rgb(76,222,228)',
              'rgb(192,192,192)',
              'rgb(102,0,51)',
              'rgb(153,204,255)',
              'rgb(255,204,255)',
              'rgb(22,221,53)',
              'rgb(150,155,116)',
              'rgb(0,65,89)',
              'rgb(176,0,81)',
              'rgb(78,97,114)',
              'rgb(178,162,150)',
              'rgb(223,210,124)',
              'rgb(228,153,105)',
            ],
            hoverOffset: 4
          }
        ]
      }
    }); 
  };
  return (
    <div> 
  <main id="main" className="main">
    <div className="pagetitle">
      <h1>Dashboard</h1>
      <nav>
        {/* <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="home">Home</a></li>
          <li className="breadcrumb-item active">Dashboard</li>
        </ol> */}
      </nav>
    </div> 
    <section className="section dashboard">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
          <div className="col-xxl-4 col-md-4">
              <div className="card info-card revenue-card">  
                <div className="card-body">
                  <h5 className="card-title">Sales <span>| Today</span></h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-currency-rupee" />
                      <LuIndianRupee />
                    </div>
                    <div className="ps-3">
                      <h6><span id="this_month">{daySales}</span></h6>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-md-4">
              <div className="card info-card revenue-card">  
                <div className="card-body">
                  <h5 className="card-title">Revenue <span>| This Month</span></h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i className="bi bi-currency-rupee" />
                      <LuIndianRupee />
                    </div>
                    <div className="ps-3">
                      <h6><span id="this_month">{monthSales}</span></h6>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-md-4">
              <div className="card info-card revenue-card">  
                <div className="card-body">
                  <h5 className="card-title">Revenue <span>| This Year</span></h5>
                  <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                    <LuIndianRupee />
                    </div>
                    <div className="ps-3">
                      <h6><span id="this_year">{yearSales}</span></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title" id="currentYear">Outlet Sales 2024</h2>
                    <canvas id="pieChart" style={{"maxHeight":"400px"}} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title" id="currentYearsales">Month Wise Sales 2024</h5>
                    <canvas id="barChart" style={{"maxHeight":"400px"}} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card recent-sales overflow-auto">
                <div className="filter">
                  <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots" /></a>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li className="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>
                    <li><a className="dropdown-item" href="#">Today</a></li>
                    <li><a className="dropdown-item" href="#">This Month</a></li>
                    <li><a className="dropdown-item" href="#">This Year</a></li>
                  </ul>
                </div>
                <div className="card-body">
                    <h5 className="card-title">Recent Orders </h5>
                    <table className="table  ">
                        <thead> 
                          <tr>
                              <th scope="col">OrderId</th>
                              <th scope="col">Customer</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Status</th>
                              <th scope="col">Sales Executive</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                            recentOrder.map(x=>{
                              return (
                                <tr key={x.id}>
                                <td scope="col"><span className='orderId' onClick={()=>orderView(x.orderId)}>{x.orderId}</span></td>
                                <td scope="col">{x.customerName}</td>
                                <td scope="col">{x.orderAmount}</td>
                                <td scope="col">{x?.orderstatus}</td>
                                <td scope="col">{x.se_name}</td>
                            </tr>
                              )
                            })
                          } 
                        </tbody>
                    </table>
                  </div>  
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {OrderviewModal ? <Orderview 
      isShow={OrderviewModal} 
      close={OrderviewModalClose}
      viewOrderId={viewOrderId} 
      resetTableRender={()=>setTableRender(!tableRender)} 
       /> : ''}
  </main>
    </div>
  )
}

export default Dashboard