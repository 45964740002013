import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideBar from "./components/sideBar/SideBar";
import Header from "./components/header/Header";
import { Container, Row, Col } from "react-bootstrap";
import './App.css'
import UserAuth from './lib/userAuth'
import { Navigate} from "react-router-dom";
const ScrollToTop = () => {
  const { pathname } = useLocation(); 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function LoggedView() {
  
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false); 
  useEffect(() => {
    if(window.innerWidth <= 991) {
      setIsNavVisible(false);
      setIsMobileView(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    handleResize();
  }, [isNavVisible])
  
  const handleResize = (event) => {
    if(window.innerWidth <= 991) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }  

  return UserAuth() ? (
      <div className="row w-100 main-wrapper">
        {
          isNavVisible &&
          <div className="col col-2" style={{ padding: 0}}>
            <SideBar setIsNavVisible={setIsNavVisible} isMobileView={isMobileView} />
          </div>
        }
        <ScrollToTop />
        <div className={`col topnav-wrapper ${isMobileView ? 'col-12' : (isNavVisible? 'col-10' : 'col-12')}`}>
          <Header isNavVisible={isNavVisible} setIsNavVisible={setIsNavVisible} />
          <div className={`main-view1 ${isMobileView? 'mobile-view' : (isNavVisible? '' : 'mobile-view')}`}>
            <div className="container1 p-4">
              <Outlet />
            </div>
          </div>
          
        </div>    
        <footer id="footer" class="footer">
            <center> 
              <div class="copyright">© Copyright <strong><span>Rolloverstock</span></strong>. All Rights Reserved</div>
            </center>
          </footer> 
      </div>
      
  ):<Navigate to="/login"  replace />
}

export default LoggedView;
