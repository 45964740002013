import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'; 
// import { useDispatch } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { fetchStates ,FetchStateByPincode as fetchStateByPincodeApi, updateCustomers, fetchCustomersById,AddCustomers} from "../../../services/Customer/customer";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { styled } from "@mui/material/styles";
import Form from 'react-bootstrap/Form';
  const RequiredAsterisk = styled("span")({
    color: "red",
  });
const EditCustomer = ({custDataRerander, isEditShow, close,editCustomerId,type }) => {
    const handleShow = () => setShow(true);
    
    const [billingCity, setBillingCity] = useState("");
    const [shippingCity, setShippingCity] = useState("");
    const [bAddressLine2, setbAddressLine2] = useState("");
    const [sAddressLine2, setsAddressLine2] = useState(""); 
    const [customerName, setCustomerName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [gstNumber, setGstNumber] = useState(""); 
    const [pincode, setPincode] = useState();
    const [billingAddress, setBillingAddress] = useState("");
    const [shippingAddress, setShippingAddress] = useState("");
    const [state, setState] = useState();
    const [bstateError, setBStateError] = useState(false);
    const [billingPincodeError, setBillingPincodeError] = useState(false);
    const [billState, setBillState] = useState();
    const [bstateName, setBStateName] = useState("");
    const [billingPincode, setBillingPincode] = useState();
    const [stateName, setStateName] = useState("");
    const [stateData, setStateData] = useState([]); 
    const [customerid, setcustomerid] = useState("");
    const [customerNameError, setCustomerNameError] = useState(false);
    const [mobileNumberError, setMobileNumberError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [gstNumberError, setgstNumberError] = useState(false); 
    const [pincodeError, setPincodeError] = useState(false);
    const [billingAddressError, setBillingAddresseError] = useState(false);
    const [billingCityError, setBillingCityError] = useState(false);
    const [shippingCityError, setShippingCityError] = useState(false);
    const [shippingAddressError, setShippingAddressError] = useState(false);
    const [shipStateError, setShipStateError] = useState(false);
    const [isSameAsBilling, setIsSameAsBilling] = useState(false);
    const [loading, setLoading] = useState(false); 
    const customerNameRef = useRef(null);
    const mobileNumberRef = useRef(null);
    const emailRef = useRef(null);
    const gstNumberRef = useRef(null);
    const pincodeRef = useRef(null);
    const billingAddressRef = useRef(null);
    const billaddressline2 = useRef(null);
    const shippingAddressRef = useRef(null);
    const shipaddressline2 = useRef(null);
    const stateRef = useRef(null);
    const bstateRef = useRef(null);
    const bpincodeRef = useRef(null);

    const handleKeyDown = (e, nextRef) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            nextRef.current.focus();
        }
    };
    const handleSave = async() => {
        if (!shippingCity.trim() || !billingCity.trim()  || !customerName.trim() || !mobileNumber.trim()  || !pincode || !billingAddress.trim() || !shippingAddress.trim() || !stateName || !billingPincode || !bstateName) {
          setShippingCityError(!shippingCity.trim())
          setBillingCityError(!billingCity.trim())
          setCustomerNameError(!customerName.trim())
          setMobileNumberError(!mobileNumber.trim())
        //   setEmailError(!email.trim())
        //   setgstNumberError(!gstNumber.trim()) 
          setPincodeError(!pincode)  
          setBillingAddresseError(!billingAddress.trim())  
          setShippingAddressError(!shippingAddress.trim())
          setShipStateError(!stateName)
          setBStateError(!billState)
          setBillingPincodeError(!billingPincode)

      let object=[{
        key:customerName.trim(),
        ref:customerNameRef.current,
      },
      {
        key:mobileNumber.trim(),
        ref:mobileNumberRef.current,
      },
    //   {
    //     key:email.trim(),
    //     ref:emailRef.current,
    //   },
    //   {
    //     key:gstNumber.trim(),
    //     ref:gstNumberRef.current,
    //   },
      {
        key:billingAddress.trim(),
        ref:billingAddressRef.current,
      },
      {
        key:shippingAddress.trim(),
        ref:shippingAddressRef.current,
      },
       {
        key:billingPincode,
        ref:bpincodeRef.current,
      },
      {
        key:pincode,
        ref:pincodeRef.current,
      },
      {
        key:bstateName,
        ref:bstateRef.current,
      },
       {
        key:stateName,
        ref:stateRef.current,
      },      
    ]
    for(let i=0;i<object.length;i++){
      if (!object[i].key) {  
        object[i].ref.focus();
        break;
      } 
    }
     return
    }
    setCustomerNameError(false)
    setMobileNumberError(false)
    // setEmailError(false)
    // setgstNumberError(false) 
    setPincodeError(false)
    setBillingAddresseError(false)
    setShippingAddressError(false)
    setShipStateError(false)
    setBStateError(false)
    setBillingPincodeError(false)
       let updatedData = { 
            customer_name: customerName,
            phone_number: mobileNumber,
            email:email,
            gst_number: gstNumber,
            state:state,
            billing_address: billingAddress,
            shiping_address: shippingAddress,
            billingpincode:billingPincode,
            billingstate:billState,
            pincode:pincode,
            billing_city:billingCity,
            shipping_city:shippingCity,
            shpping_address_line_2:sAddressLine2,
            billing_address_line_2:bAddressLine2
        }; 
        if(type=='EDIT'){
            updatedData={...updatedData,id:editCustomerId}
        }
        setLoading(true)
        var response={};
        if(type=='CREATE'){
              response =  await AddCustomers(updatedData);
        }else{
              response =  await updateCustomers(updatedData);
        }
        
    if (response && response.data && response.data.status === true) {
            handleClose();
            custDataRerander();
            toast.success(type=='CREATE'?'Customer Created Successfully':'Customer Updated Successfully');
            setLoading(false)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 3000);      
        }
    };
     
  const fetchStateByPincode = async (pincode) => {
        try {
            const stateResponse = await fetchStateByPincodeApi(pincode);
            setState(stateResponse.data.data.StateId.id);
            setStateName(stateResponse.data.data.StateId.state_name);
        } catch (error) {
            toast.error('Please enter valid pincode');
        }
    };
     const fetchBillingStateByPincode = async (billingPincode) => {
        try {
            const stateResponse = await fetchStateByPincodeApi(billingPincode);
            setBillState(stateResponse.data.data.StateId.id);
            setBStateName(stateResponse.data.data.StateId.state_name);
            if(isSameAsBilling){
                setState(stateResponse.data.data.StateId.id) 
            } 
        } catch (error) {
            toast.error('Please enter valid pincode');
        }
    };
    const handleSameAddressChange = (e) => {
            if (e.target.checked) { 
                setIsSameAsBilling(true)
                setShippingAddress(billingAddress);
                setPincode(billingPincode)
                setShippingCity(billingCity)
                setPincodeError(false)
                setShippingAddressError(false)
                setShippingCityError(false)
                setShipStateError(false)
                setsAddressLine2(bAddressLine2)

            } else { 
                setIsSameAsBilling(false)
                // setShippingAddress('');
                // setPincode('')
                // setStateName('')
            }
        };

    useEffect(() => {
        if(type!='CREATE'){
            fetchCustomersById(editCustomerId)
        .then((response) => {
        if (response.status) { 
            setCustomerName(response.data.customer_name)
            setMobileNumber(response.data.phone_number)
            setEmail(response.data.email)
            setGstNumber(response.data.gst_number) 
            setBillingAddress(response.data.billing_address)
            setShippingAddress(response.data.shiping_address)
            setState(response.data.state)
            setPincode(response.data.pincode)
            setBillingPincode(response.data.billingpincode)
            setBillState(response.data.billingstate)
            setcustomerid(response.data.id)
            setBillingCity(response.data?.billing_city!=null?response.data?.billing_city:'')
            setShippingCity(response.data?.shipping_city!=null?response.data?.shipping_city:'')
            setbAddressLine2(response.data.shpping_address_line_2)
            setsAddressLine2(response.data.billing_address_line_2) 
        }
        })
        .catch((error) => {
        toast.error('Error fetching customers:', error);
        });

        }
        
    }, [customerid]);
    
    useEffect(()=>{
         fetchStates()
    .then((response) => {
      if (response) {
        setStateData(response.data.data);
      }
    })
    .catch((error) => {
    //   toast.error('Error fetching customers:', error);
    });
    },[])

    useEffect(() => {
        if (billingPincode && billingPincode.toString().length === 6) {
            fetchBillingStateByPincode(billingPincode);
        }
    }, [billingPincode]);
    useEffect(() => {
        if (pincode && pincode.toString().length === 6) {
            fetchStateByPincode(pincode);
        }
    }, [pincode]);

        const handleStateNameChange = (selected) => {
         const selectedState = stateData.find((statee) => statee.state_name === selected[0]);
          setStateName(selected[0]);
          setShipStateError(false)
        if (selectedState) {
           setState(selectedState.id);
            }
        };

        const handleBillingStateNameChange = (selected) => {
         const selectedState = stateData.find((statee) => statee.state_name === selected[0]);
          setBStateName(selected[0]);
          setBStateError(false)
        if (selectedState) {
           setBillState(selectedState.id);
                if(isSameAsBilling){
                    setState(selectedState.id)
                    setStateName(selected[0])
                }
            }
        };

    const handleClose = () => { setShow(false); close() };
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(isEditShow)
    }, [isEditShow]);
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl">
                  
            <Modal.Header closeButton>
                <h4><Icon.PersonPlus /></h4>&nbsp;
                <h5 className="modal-title">  {type=='CREATE'?'Add Customer Data':'Edit Customer Data'}   </h5>
                {/* <h5 className="modal-title"> Edit Customer Data {editCustomerId}</h5> */}
            </Modal.Header>
            <Modal.Body>
                
                <form className="row g-3 needs-validation" id="createCustomerForm" method="post" >
                    <div className="col-6 px-3">
                        <label htmlFor="NewCustomerName" className="form-label required">
                        <Icon.Person /> Customer Name <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            maxLength={50}
                            type="text"
                            className="form-control"
                            id="NewCustomerName"
                            name="customerName"
                            value={customerName}
                            onChange={(e) => {setCustomerName(e.target.value)
                            if (customerNameError && e.target.value.trim() !== '') {
                                  setCustomerNameError(false);
                             } }}
                            ref={customerNameRef}
                            placeholder="Enter Customer Name"
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: customerNameError ? 'block' : 'none' }}>
                            Customer name is required
                        </Form.Control.Feedback>
                        <div className="new-customer-name invalid-feedback">Please provide Customer Name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewMobileNumber" className="form-label required">
                        <Icon.Phone /> Mobile Number <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            maxLength={15}
                            type="text"
                            className="form-control"
                            id="NewMobileNumber"
                            name="mobileNumber"
                            value={mobileNumber}
                            onChange={(e) => {setMobileNumber(e.target.value)
                            if (mobileNumberError && e.target.value.trim() !=='' && !isNaN(e.target.value.trim())) {
                                setMobileNumberError(false)
                            }}} 
                            ref={mobileNumberRef}
                            placeholder="Enter Mobile Number"
                            pattern="[0-9]*" 
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: mobileNumberError ? 'block' : 'none' }}>
                            Mobile number is required
                        </Form.Control.Feedback>
                        <div className="new-mobile-number invalid-feedback">Please provide a valid Mobile Number.</div>
                    </div>

                    <div className="col-6 px-3">
                        <label htmlFor="email" className="form-label required"><Icon.Envelope /> Email-ID </label>
                        <input maxLength={50} type="text" className="form-control" id="email" value={email}
                            onChange={(e) => {setEmail(e.target.value)
                            if (emailError && e.target.value.trim() !=='') {
                                setEmailError(false)
                            }}} ref={emailRef} placeholder="Enter Customer Email-ID" />
                            <Form.Control.Feedback type="invalid" style={{ display: emailError ? 'block' : 'none' }}>
                            Email-ID is required
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please provide name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="gst" className="form-label required"><Icon.CashCoin /> GST Number </label>
                        <input maxLength={20} type="text" className="form-control" id="gst" value={gstNumber}
                             onChange={(e) => {setGstNumber(e.target.value)
                            if (gstNumberError && e.target.value.trim() !=='') {
                                setgstNumberError(false)
                            }}} ref={gstNumberRef} placeholder="Enter GST Number" />
                            <Form.Control.Feedback type="invalid" style={{ display: gstNumberError ? 'block' : 'none' }}>
                            GST Number required
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please provide name.</div>
                    </div> 


                    <div className="col-6 px-3">
                        <label htmlFor="NewBillingAddress" className="form-label required">
                        <Icon.Buildings/> Billing Address Line 1 <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            maxLength={75}
                            className="form-control"
                            name="billingAddress"
                            id="NewBillingAddress"
                            value={billingAddress}
                             onChange={(e) => {
                                setBillingAddress(e.target.value)
                                if(isSameAsBilling){
                                    setShippingAddress(e.target.value)
                                }
                                if (billingAddressError && e.target.value.trim() !=='') {
                                    setBillingAddresseError(false)
                                }}}
                            onKeyDown={(e) => handleKeyDown(e, billaddressline2)}
                            ref={billingAddressRef}
                            placeholder="Enter Address Line 1" 
                        />  
                        <Form.Control.Feedback type="invalid" style={{ display: billingAddressError ? 'block' : 'none' }}>
                            Billing address is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Billing Address.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewShippingAddress" className="form-label required">
                        <Icon.Building/> Shipping Address Line 1 <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            maxLength   =   {75}
                            disabled    =   {isSameAsBilling?true:false}
                            className   =   "form-control"
                            name        =   "shippingAddress"
                            id          =   "NewShippingAddress"
                            value       =   {shippingAddress}
                            onChange    =   {(e) => {setShippingAddress(e.target.value)
                                            if (shippingAddressError && e.target.value.trim() !=='') {
                                                setShippingAddressError(false)
                                            }}}
                            onKeyDown={(e) => handleKeyDown(e, shipaddressline2)}
                            ref         =   {shippingAddressRef}
                            placeholder =   "Enter Address Line 1" 
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: shippingAddressError ? 'block' : 'none' }}>
                            Shipping address is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Shipping Address.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewbAddressLine2" className="form-label">
                        <Icon.Buildings/> Billing Address Line 2
                        </label>
                        <input
                            maxLength={75}
                            className="form-control" 
                            id="NewbAddressLine2"
                            value={bAddressLine2}
                             onChange={(e) => {
                                setbAddressLine2(e.target.value)
                                if(isSameAsBilling){
                                    setsAddressLine2(e.target.value)
                                }}}
                            onKeyDown={(e) => handleKeyDown(e, pincodeRef)}
                            placeholder="Enter Address Line 2"
                            ref={billaddressline2} 
                        />  
                         
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewsAddressLine2" className="form-label ">
                        <Icon.Building/> Shipping Address Line 2
                        </label>
                        <input
                            maxLength   =   {75}
                            disabled    =   {isSameAsBilling?true:false}
                            className   =   "form-control" 
                            id          =   "NewsAddressLine2"
                            value       =   {sAddressLine2}
                            ref={shipaddressline2} 
                            onChange    =   {(e) => {setsAddressLine2(e.target.value)
                                             }} 
                            placeholder =   "Enter Address Line 2" 
                        />
                    </div>

                    <div className="col-2 px-3">
                        <label htmlFor="bill_pin" className="form-label required"><Icon.Geo/> Billing Pincode <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input maxLength={6} type="text" className="form-control" id="bill_pin" value={billingPincode}
                            onChange={(e) => {
                                setBillingPincode(parseInt(e.target.value,10)||'')
                                if(isSameAsBilling){
                                    setPincode(parseInt(e.target.value,10)||'')
                                }
                            if (billingPincodeError && e.target.value.trim() !=='' && !isNaN(e.target.value.trim())) {
                                setBillingPincodeError(false)
                            }}} ref={bpincodeRef} placeholder="Enter Pincode" />
                            <Form.Control.Feedback type="invalid" style={{ display: billingPincodeError ? 'block' : 'none' }}>
                           Billing Pincode is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div> 
                    <div className="col-2 px-3">
                        <label htmlFor="bill_state" className="form-label required"><Icon.GeoAlt/>Billing State</label>
                         <Typeahead
                            id="bill_state"
                            labelKey="bstate_name" 
                            onChange={handleBillingStateNameChange}
                            ref={bstateRef}
                            options={stateData.map((statee) => statee.state_name)}
                            placeholder="Billing State"
                            selected={bstateName ? [bstateName] : []}
                            className="form-control"
                            style={{ marginBottom: '10px' }}
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: bstateError ? 'block' : 'none' }}>
                          Billing  State name is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div> 
                    <div className="col-2 px-3">
                        <label htmlFor="NewBillingcity" className="form-label required">
                        <Icon.Buildings/> Billing City <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            maxLength={30}
                            className="form-control" 
                            id="NewBillingcity"
                            value={billingCity}
                             onChange={(e) => {
                                setBillingCity(e.target.value)
                                if(isSameAsBilling){
                                    setShippingCity(e.target.value)
                                }
                                if (billingCityError && e.target.value.trim() !=='') {
                                    setBillingCityError(false)
                                }
                                 }}
                            onKeyDown={(e) => handleKeyDown(e, billingAddressRef)}
                            ref={billingAddressRef}
                            placeholder="Enter City" 
                        />  
                        <Form.Control.Feedback type="invalid" style={{ display: billingCityError ? 'block' : 'none' }}>
                            Billing city is required
                        </Form.Control.Feedback>  
                    </div>
                    <div className="col-2 px-3">
                        <label htmlFor="shipping_pin" className="form-label required"><Icon.Geo/> Shipping Pincode <RequiredAsterisk>*</RequiredAsterisk></label>
                        <input maxLength={6} disabled={isSameAsBilling?true:false} type="text" className="form-control" id="shipping_pin" value={pincode}   
                            onChange={(e) => {setPincode(parseInt(e.target.value,10)||'') 
                            if (pincodeError && e.target.value.trim() !=='' && !isNaN(e.target.value.trim())) {
                                setPincodeError(false)
                            }}}  ref={pincodeRef} placeholder="Enter Pincode" />
                            <Form.Control.Feedback type="invalid" style={{ display: pincodeError ? 'block' : 'none' }}>
                           Shipping Pincode is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div> 
                   
                    <div className="col-2 px-3">
                        <label htmlFor="spstate" className="form-label required"><Icon.GeoAlt/> Shipping State</label>
                         <Typeahead
                            disabled={isSameAsBilling?true:false}
                            id="spstate"
                            onChange={handleStateNameChange}
                            ref={stateRef}
                            labelKey="state_name" 
                            options={stateData.map((shipstate) => shipstate.state_name)}
                            placeholder="Shipping State"
                            selected={stateName ? [stateName] : []}
                            className="form-control"
                            style={{ marginBottom: '10px' }}
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: shipStateError   ? 'block' : 'none' }}>
                           Shipping State name is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide description.</div>
                    </div> 
                    <div className="col-2 px-3">
                        <label htmlFor="NewShippingAddress" className="form-label required">
                        <Icon.Building/> Shipping City <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            maxLength   =   {30}
                            disabled    =   {isSameAsBilling?true:false}
                            className   =   "form-control" 
                            id          =   "NewShippingAddress"
                            value       =   {shippingCity}
                            onChange    =   {(e) => {setShippingCity(e.target.value)
                                                if (shippingCityError && e.target.value.trim() !=='') {
                                                    setShippingCityError(false)
                                                }
                                             }}
                            onKeyDown={(e) => handleKeyDown(e, shippingAddressRef)}
                            ref         =   {shippingAddressRef}
                            placeholder =   "Enter City" 
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: shippingCityError ? 'block' : 'none' }}>
                            Shipping city is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Shipping Address.</div>
                    </div>



                    <div className="col-12 px-3">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="sameAsBillingAddress"
                            onChange={handleSameAddressChange}
                            checked={isSameAsBilling}
                        />
                        <label className="form-check-label" htmlFor="sameAsBillingAddress">
                            Shipping address is the same as billing address
                        </label>
                    </div>
                </div> 
                    <div className="modal-footer" >
                        <Button variant="danger approvebtn" disabled={loading} onClick={handleSave}>Save</Button>
                        <Button variant="danger rejectbtn" onClick={handleClose}> Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
};

export default EditCustomer;
