import React, { useState,useEffect,useRef } from 'react'
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Row, Col, InputGroup, Button, FormControl } from 'react-bootstrap'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import * as Icon from 'react-bootstrap-icons';
import { fetchCustomers ,FetchStateByPincode, searchCustomers} from '../../services/Customer/customer';
import {  fetchOutlets,searchProducts, updateOrder, searchOutletsByName} from '../../services/Order/Order';
import Accordion from 'react-bootstrap/Accordion';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { FaSearch} from "react-icons/fa";
import '../createNewOrder/createNewOrder.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { styled } from "@mui/material/styles";
import EditItems from './EditItems';
import {  searchusersByRole } from '../../services/User/User';
import { fetchOrderDetailsById } from "../../services/Order/Order";
import { useNavigate } from 'react-router-dom'
var currentDate = new Date();
var year = currentDate.getFullYear();
var month = currentDate.getMonth() + 1;
var day = currentDate.getDate();
var formattedDate = year+'-' +month+'-' +day;  
  const RequiredAsterisk = styled("span")({
    color: "red",
  }); 
function EditOrder(){
    const { editOrderId } = useParams();
    const typeaheadRef = useRef();
  const renderOption = (option) => <div>{option?.name}</div>;
  const [selected, setSelected] = useState([]);  
  const [timestamp, setTimestamp] = useState(getFormattedTimestamp());
  const [orderId, setOrderId] = useState(atob(editOrderId));
  const [customerId, setCustomerId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [outletId, setOutletId] = useState( );
  const [outletName, setOutletName] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [salesExecutiveId, setSalesExecutiveId] = useState('');
  const [salesExecutiveName, setSalesExecutiveName] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [pincodee, setPincode] = useState( );
  const [shipping_address_pincode, setShippingAddressPincode] = useState( );
  const [landmark, setLandmark] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [stateOfSupply, setStateOfSupply] = useState('');
  const [stateOfDelivery, setStateOfDelivery] = useState('');
  const [stateOfDeliveryID, setStateOfDeliveryID] = useState('');
  const [orderAmount, setOrderAmount] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const [customerData, setCustomerData] = useState([]);
  const [outletData, setOutletData]=useState([])
  const [productOptions, setProductOptions] = useState([]);
  const [productSearch, setProductSearch] = useState('');
  const [userData, setUserData] = useState([]);
  const [stateId,setStateOfSupplyId]=useState('')
  const [pincode,setStateOfSupplyPincode]=useState( )
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [customerNameError, setCustomerNameError] = useState(false);
  const [outletNameError, setOutletNameError] = useState(false);
  const [landmarkError, setLandmarkError] = useState(false);
  const [dueDateError, setDueDateError] = useState(false);
  const [salesExecutiveNameError, setSalesExecutiveNameError] = useState(false);
  const [orderCreated, setOrderCreated] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredOutletOptions, setFilteredOutletOptions] = useState([]);
  const [filteredSalesExecutiveOptions, setFilteredSalesExecutiveOptions] = useState([]);
  const [shippingPincodeError, setShippingPincodeError] = useState('');
  const [billingPincodeError, setBillingPincodeError] = useState('');
  const [shippingAddressError, setShippingAddressError] = useState('');
  const [billingAddressError, setBillingAddressError] = useState('');
  const billingAddressRef = useRef(null);
  const shippingAddressRef = useRef(null);
  const [accordionOpen, setAccordionOpen] = useState(true);
  const customerNameRef = useRef(null);
  const outletNameRef = useRef(null);
  const salesExecutiveNameRef = useRef(null);
  const landmarkRef = useRef(null);
  const dueDateRef = useRef(null);
   const pincodeRef = useRef(null);
  const bpincodeRef = useRef(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [searchCustomerName,setSearchCustomerName]=useState('')
  const [searchUserName,setSearchUserName]=useState('')
  const [searchOutlets,setSearchOutlets]=useState('')
  const [searchInputs,setSearchInputs]=useState('')
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
useEffect(()=>{ 
    fetchOrderDetailsById(orderId,true).then(async (result) => {  
      if(result?.customerId!=undefined){
        setCustomerId(result?.customerId)
        setCustomerName(result?.customerName)
        setCustomerEmail(result?.customerEmail)
        setCustomerNumber(result?.customerNumber)
        setBillingAddress(result?.billing_address)
        setShippingAddress(result?.shipping_address)
        setLandmark(result?.landmark)
        setPincode(result?.billing_address_pincode)
        setShippingAddressPincode(result?.shipping_address_pincode) 
        setTotalPrice(result?.orderAmount)
        setOrderDate(result?.orderDate)
        setGstNumber(result?.customer?.gst_number)
        setStateOfDelivery(result?.sod?.state_name)
        setStateOfDeliveryID((result?.sod?.id)) 
        setStateOfSupply(result?.sos?.state_name)
        setStateOfSupplyId(result?.sos?.id)
        setSalesExecutiveId(result?.sales_executive_id);
        setSalesExecutiveName(result?.users?.name);
        setOutletId(result?.outlet_id);
        setOutletName(result?.outlets?.outlet_name);  
         const dueDate = result?.dueDate; 
          if (dueDate) {
            const parsedDueDate = new Date(dueDate);
            if (!isNaN(parsedDueDate.getTime())) {
              setDueDate(parsedDueDate);
            } else {
              console.error("Invalid due date format:", dueDate);
            }
          } else {
            console.warn("No due date provided in the result.");
          }
        var  items=  result?.order_items.map((items)=>{
             return {
                id: items?.id,
                orderId:items?.orderId,
                item:items?.products.name,
                quantity:items?.quantity,
                product_id:items?.product_id,
                unit_type:items?.unit_type,
                unit_price:items?.unit_price,
                base_price:items?.base_price,
                discount:items?.discount,
                discount_amount:items?.discount_amount,
                tax:items?.tax,
                tax_amount:items?.tax_amount,
                total_price:items?.total_price,
            };
        })
        setSelectedProducts(items)   
      }else{ 
        navigate('/dashboard');
      } 
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
},[])

  let removeItem=(pid)=>{  
    setSelectedProducts(selectedProducts.filter(item => item.product_id !== pid)); 
  }  
  const handleResetState=()=>{
    setCustomerId(''); setCustomerName('');setOutletId('');setOutletName('');setCustomerNumber('');setCustomerEmail('');
    setSalesExecutiveId('');setSalesExecutiveName('');setGstNumber('');setShippingAddressPincode('');setPincode('');setLandmark(''); setBillingAddress(''); 
    setShippingAddress('');setDueDate(''); setStateOfSupply('');setStateOfDelivery(''); setOrderAmount(''); setOrderDate('');
    setCustomerData([]); setOutletData([]);setProductOptions([]); setProductSearch('');setUserData([]);setStateOfSupplyId('');
    setStateOfSupplyPincode('');setSelectedProducts([]);setTotalPrice(''); setFilteredSalesExecutiveOptions([]);
    setFilteredOutletOptions([]);setFilteredOptions([]);setStateOfDeliveryID('');
  }
   const handleUpdateOrder = async() => {

    if (!customerName|| !outletName|| !landmark|| !dueDate|| !salesExecutiveName || !shippingAddress || !billingAddress || !pincodee ||!shipping_address_pincode) {
          setCustomerNameError(!customerName)
          setOutletNameError(!outletName)
          setLandmarkError(!landmark)
          setDueDateError(!dueDate)
          setSalesExecutiveNameError(!salesExecutiveName)  
          setShippingAddressError(!shippingAddress)
          setBillingAddressError(!billingAddress)
          setBillingPincodeError(!pincodee)
          setShippingPincodeError(!shipping_address_pincode)
          setAccordionOpen(true); 

      let object=[{
        key:customerName,
        ref:customerNameRef.current,
      },
      {
        key:outletName,
        ref:outletNameRef.current,
      },
      {
        key:salesExecutiveName,
        ref:salesExecutiveNameRef.current,
      },
      {
        key:landmark,
        ref:landmarkRef.current,
      },
      {
        key:pincode,
        ref:bpincodeRef.current,
      },
      {
        key:shipping_address_pincode,
        ref:pincodeRef.current,
      },
      {
        key:shippingAddress,
        ref:shippingAddressRef.current,
      },
      {
        key:billingAddress,
        ref:billingAddressRef.current,
      },
      {
        key:dueDateRef,
        ref:dueDateRef.current,
      },
    ]
    for(let i=0;i<object.length;i++){
      if (!object[i].key) {  
        object[i].ref.focus();
        break;
      } 
    }
    if (!dueDate) {
           setDatePickerOpen(true);
    }
     return
    }
    setLandmarkError(false)
    setCustomerNameError(false)
    setOutletNameError(false)
    setDueDateError(false)
    setSalesExecutiveNameError(false)
    const inputData = {            
            orderId: orderId,
            customerId:customerId ,
            customerName: customerName,
            outletId:outletId ,
            customerNumber:customerNumber ,
            customerEmail:customerEmail ,
            salesExecutiveId:salesExecutiveId ,
            billing_address_pincode:pincodee,
            shipping_address_pincode:shipping_address_pincode,
            landmark:landmark ,
            billing_address: billingAddress,
            shipping_address:shippingAddress ,
            dueDate: dueDate,
            stateOfSupply: stateId,
            stateOfDelivery:stateOfDeliveryID,
            orderAmount: totalPrice,
            orderDate:orderDate ,
            items: selectedProducts
          } 
        const response =  await updateOrder(inputData);
        setLoading(true)
        setAccordionOpen(false); 
        if (response.status === 200 && response.data.status) {
          setSelectedProducts([]);setTotalPrice('');
            setOrderCreated(true);
            toast.success("Order Updated successfully");
            setLoading(false)
            setTimeout(() => {
                    setOrderCreated(false);
                    handleResetState()
                    setAccordionOpen(true);
                    setOrderDate(formattedDate);
                    navigate('/draftrequests');
                    
            }, 5000);

        }else{
          setAccordionOpen(true);
          toast.error(response.data.errorMessage);
          setTimeout(() => {
          setLoading(false)
          }, 4000);
        } 
        return  
     };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimestamp(getFormattedTimestamp());
    }, 60000);
    setOrderDate(formattedDate);

    return () => clearInterval(intervalId);
  }, []);

  const fetchStateByPincode = async (pincode) => {
        try {
            const stateResponse = await FetchStateByPincode(pincode);
            setStateOfSupplyId(stateResponse.data.data.StateId.id);
            setStateOfSupply(stateResponse.data.data.StateId.state_name);
        } catch (error) {
            toast.error('Error fetching state');
        }
    };

   useEffect(() => {
    if (pincode) {
        fetchStateByPincode(pincode);
    }
  }, [pincode]);

  useEffect(() => {
    if (searchCustomerName) {
            searchCustomers(searchCustomerName)
            .then((response) => {
              setCustomerData(response.data.data)
              const customerData = response.data.data;
              const filteredOptions = customerData.filter((customer) =>
                customer.customer_name.toLowerCase().includes(searchCustomerName.toLowerCase())
              );

              setFilteredOptions(filteredOptions.map((customer) => customer.customer_name));
            })
            .catch((error) => {
              // toast.error('Error occurred:', error);
            });
        }
  }, [searchCustomerName]);

  useEffect(() => {
  if (searchUserName) {
    searchusersByRole(searchUserName)
    .then((response) => {
        const userData = response.data.data;
        const usersArray = Array.isArray(userData) ? userData : [userData];
          setUserData(usersArray)
        const filteredUsers = usersArray.filter((user) =>
            user.name.toLowerCase().includes(searchUserName.toLowerCase())
        );
        setFilteredSalesExecutiveOptions(filteredUsers.map((user) => user.name));
    })
    .catch((error) => {
        // toast.error('Error occurred:', error);
    });

  }
}, [searchUserName]);

  useEffect(() => {
    if (searchOutlets) {
      searchOutletsByName(searchOutlets)
        .then((response) => {
          setOutletData(response.data.data)
          const outletData = response.data.data;
          const filteredOutlets = outletData.filter((outlet) =>
            outlet.outlet_name.toLowerCase().includes(searchOutlets.toLowerCase())
          );
          setFilteredOutletOptions(filteredOutlets.map((outlet) => outlet.outlet_name));
        })
        .catch((error) => {
          // toast.error('Error occurred:', error);
        });
    }
  }, [searchOutlets]);

 useEffect(() => {

  fetchOutlets()
    .then((response) => {
      if (response.data.data) {
        setOutletData(response.data.data);
      }
    })
    .catch((error) => {
      // toast.error('Error fetching outlets:', error);
    }); 
}, [accordionOpen]);

 const searchProductsAPI = async () => {
    try {
     let productids= selectedProducts.map(item => item.product_id) 
      const response = await searchProducts(productSearch,productids);
      if (response && response.data && response.data.data) {
        setProductOptions(response.data.data)
      }else{
        setProductOptions([])
      }
    } catch (error) {
      // toast.error('Error in Fetch products:', error);
    }
  };

  useEffect(() => {
    if (productSearch.trim() !== '') {
      searchProductsAPI();
    } else {
      setProductOptions([]);
    }
  }, [productSearch]);

  useEffect(() => {   
    let total_price=selectedProducts.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.total_price), 0)
    setTotalPrice(total_price.toFixed(2))
  }, [selectedProducts]);

  let handleFormChange=(index, newData)=>{ 
    let updatedForms = [...selectedProducts];
    updatedForms[index] = newData;
    setSelectedProducts(updatedForms);
  } 

   const handleCustomerNameChange = (selected) => {
    setCustomerName(selected[0]);
    setCustomerNameError(false);

    const selectedCustomer = customerData.find((customer) => customer.customer_name === selected[0]);
    if (selectedCustomer) {
      setCustomerId(selectedCustomer.customer_id);
      setCustomerEmail(selectedCustomer.email);
      setCustomerNumber(selectedCustomer.phone_number);
      setGstNumber(selectedCustomer.gst_number);
      setBillingAddress(selectedCustomer.billing_address);
      setShippingAddress(selectedCustomer.shiping_address)
      setStateOfDelivery(selectedCustomer.sod.state_name)
      setStateOfDeliveryID(selectedCustomer.state)
      setPincode(selectedCustomer.billingpincode);
      setShippingAddressPincode(selectedCustomer.pincode)
      setShippingAddressError(false)
      setBillingAddressError(false)
      setBillingPincodeError(false)
      setShippingPincodeError(false)
    }
  };

  const handleInputChange = (inputValue) => {
    if (inputValue.trim() !== '') {
      setSearchCustomerName(inputValue)
      } else {
      setFilteredOptions([]);
    }
  };

  const handleUserNameChange = (selected) => { 
    const selectedUser = userData.find((user) => user.name === selected[0]);
    setSalesExecutiveName(selected[0]);
    setSalesExecutiveNameError(false);
    if (selectedUser) {
      setSalesExecutiveId(selectedUser.id);
    }
  };

  const handleUserInputChange = (inputValue) => {
    setSearchUserName(inputValue)
    if (inputValue.trim() !== '') {
    } else {
      setFilteredSalesExecutiveOptions([]);
    }
  };
  const handleOutletNameChange = (selected) => {
    const selectedOutlet = outletData.find((outlet) => outlet.outlet_name === selected[0]);
    setOutletName(selected[0]);
    setOutletNameError(false);
    
    if (selectedOutlet) {
      setOutletId(selectedOutlet.outlet_id);
      setStateOfSupply(selectedOutlet.sos.state_name);
      setStateOfSupplyPincode(selectedOutlet.pincode);
    }
  };

  const handleOutletInputChange = (inputValue) => {
    if (inputValue.trim() !== '') {
      setSearchOutlets(inputValue)
    } else {
      setFilteredOutletOptions([]);
    }
  };

    const fetchShippStateByPincode = async (pincode) => {
        try {
            const stateResponse = await FetchStateByPincode(pincode);
            setStateOfDeliveryID(stateResponse.data.data.StateId.id);
            setStateOfDelivery(stateResponse.data.data.StateId.state_name);
        } catch (error) {
            toast.error('Enter valid pincode');
        }
    };

    useEffect(() => {
      if (shipping_address_pincode && shipping_address_pincode.toString().length === 6) {
          fetchShippStateByPincode(shipping_address_pincode);
      }
    }, [shipping_address_pincode]);

   const handleBillPincodeChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,6}$/.test(value)) {  
            setPincode(value);
            if (billingPincodeError && value.trim().match(/^\d{6}$/)) {
                setBillingPincodeError(false);
            }
        }
    };
     const handleShipPincodeChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,6}$/.test(value)) {  //
            setShippingAddressPincode(value);
            if (shippingPincodeError && value.trim().match(/^\d{6}$/)) {
                setShippingPincodeError(false);
            }
        }
    };

  const handleLandmarkChange = (e) => {
    const value = e.target.value;
    setLandmark(value);
    setLandmarkError(!value);
  };
    const handleDueDateChange = (date) => {
    setDueDate(date);
    setDueDateError(!date);
  };
  let hasEmptyQuantity = false;

  for (let i = 0; i < selectedProducts.length; i++) {
      if (selectedProducts[i].quantity === ""|| parseFloat(selectedProducts[i].quantity) === 0) {
          hasEmptyQuantity = true;
          break;
      }
  }

  const handleProductSelect = (sp) => { 
    if(sp?.productId){
        const productExists = selectedProducts.some(product => product.product_id === sp.productId);
    if (productExists) {
          typeaheadRef.current.clear();
    }
    if (!productExists) {
      const newProduct = {         
        item: sp?.name,              
        "product_id" :sp?.productId,
        "quantity" :1,
        "unit_type" :1,
        "unit_price" :sp?.price,
        "base_price" :sp?.price,
        "discount" :0, 
        "discount_amount" :0, 
        "tax" :0,
        "tax_amount" :0, 
        "total_price" :sp?.price.toFixed(2)
      };  
      setSelectedProducts((prevProducts) => [...prevProducts, newProduct]);
      typeaheadRef.current.clear();
    }
  }
  }; 

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };

  function getFormattedTimestamp() {
    const date = new Date();
    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      hour: 'numeric',
      minute: '2-digit'
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);

    return formatter.format(date);
  }
    return (
        <> 
        <div> 
        <Row style={{ marginBottom: 16 }}>
          <Col><h3 style={{fontSize:"1.5rem"}}>Edit Order Form : {orderId}</h3></Col>
        </Row>
        <Row>
          <Col xl={8} lg={8}>
            <Row>
              <Col>
                <label htmlFor="customerName" className="form-label required">
                  <Icon.Person /> Customer Name <RequiredAsterisk>*</RequiredAsterisk>
                </label>
                <FloatingLabel>
                       <Typeahead
                    id="outletName"
                    onChange={handleCustomerNameChange}
                    ref={customerNameRef}
                    options={filteredOptions}
                    onInputChange={handleInputChange}
                    placeholder="Select Customer Name"
                    selected={customerName ? [customerName] : []}
                    className='form-control border'
                    style={{ width: '100%', paddingLeft: 12 }}
                  />
                  <Form.Control.Feedback type="invalid" style={{ display: customerNameError ? 'block' : 'none' }}>
                    Customer name is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col>
                <label className="form-label required">
                  <Icon.ShopWindow /> Outlet Name <RequiredAsterisk>*</RequiredAsterisk>
                </label>
                <FloatingLabel controlId="Enter Outlet Name" className="mb-3">
                  <Typeahead
                    id="outletName"
                    onChange={handleOutletNameChange}
                    ref={outletNameRef}
                    options={filteredOutletOptions}
                    onInputChange={handleOutletInputChange}
                    placeholder="Select Outlet Name"
                    selected={outletName ? [outletName] : []}
                    className='form-control border'
                    style={{ width: '100%', paddingLeft: 12 }}
                  />
                  <Form.Control.Feedback type="invalid" style={{ display: outletNameError ? 'block' : 'none' }}>
                    Outlet name is required
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                 <Accordion activeKey={accordionOpen ? '0' : ''} onSelect={() => setAccordionOpen(!accordionOpen)}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>More Information</Accordion.Header>
                    <Accordion.Body>
                       <Row>
                        <Col xl={6} lg={6} sm={8} style={{ marginBottom: '20px' }}>
                          <label className="form-label required">
                            <Icon.Phone /> Contact Number
                          </label>
                          <Form.Control 
                            type="text" 
                            placeholder="Enter Contact Number" 
                            value={customerNumber} 
                            style={{ width: '100%', paddingLeft: 12,  height: "45px"  }}
                            readOnly 
                          />
                        </Col>
                        <Col xl={6} lg={6} sm={6} style={{ marginBottom: '20px' }}>
                         <label  className="form-label required"><Icon.Envelope /> Email-ID</label>                          
                            <Form.Control 
                            type="email" 
                            placeholder="Enter Customer Email ID"
                            value={customerEmail} 
                            style={{ width: '100%', paddingLeft: 12,  height: "45px"  }}
                            readOnly 
                            />
                        </Col>
                        <Col xl={6} lg={6} sm={6} style={{ marginBottom: '20px' }}>
                             <label  className="form-label required"><Icon.CashCoin /> GST Number</label>
                            <Form.Control 
                            type="text" 
                            placeholder="Enter GST Number" 
                            value={gstNumber} 
                            style={{ width: '100%', paddingLeft: 12,  height: "45px"  }}
                            readOnly  
                            />
                        </Col>
                        <Col xl={6} lg={6} sm={6} style={{ marginBottom: '20px' }}>
                          <label  className="form-label required">
                        <Icon.Person /> Sales Person Name <RequiredAsterisk>*</RequiredAsterisk>
                           </label>
                         <Typeahead
                          id="salesExecutiveName"
                          onChange={handleUserNameChange}
                          options={filteredSalesExecutiveOptions}
                          onInputChange={handleUserInputChange}
                          ref={salesExecutiveNameRef}
                          placeholder="Select Sales Executive "
                          selected={salesExecutiveName ? [salesExecutiveName] : []}
                          className='form-control border'
                          style={{ width: '100%', paddingLeft: 12, height: "45px"  }}
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: salesExecutiveNameError ? 'block' : 'none' }}>
                          Sales Executive name is required
                        </Form.Control.Feedback>     
                        </Col>
                        <Col xl={6} lg={6} sm={6} style={{ marginBottom: '20px' }} >
                        <label  className="form-label required">
                        <Icon.Buildings/> Landmark <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                           <FormControl
                        id="landmark"
                        value={landmark}
                        ref={landmarkRef}
                        onChange={(e) => {setLandmark(e.target.value)
                            if (landmarkError && e.target.value.trim() !== '') {
                                  setLandmarkError(false);
                             } }}
                        placeholder="Enter landmark"
                        className='form-control border'
                        style={{ width: '100%', paddingLeft: 12, height: "45px"  }}
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: landmarkError ? 'block' : 'none' }}>
                          Landmark is required
                        </Form.Control.Feedback>   
                        </Col>
                        <Col xl={6} lg={6} sm={6} style={{ marginBottom: '20px' }}>
                          <label  className="form-label required"><Icon.Geo/>Shipping Address Pincode</label>
                            <Form.Control type="text" placeholder="Enter Pincode" value={shipping_address_pincode}  
                            onChange={handleShipPincodeChange} ref={pincodeRef}
                            style={{ width: '100%', paddingLeft: 12, height: "45px"  }}/>
                            <Form.Control.Feedback type="invalid" style={{ display: shippingPincodeError ? 'block' : 'none' }}>
                          Shipping Pincode is required
                          </Form.Control.Feedback> 
                        </Col>
                       
                        <Col xl={6} lg={6} sm={6} style={{ marginBottom: '20px' }}>
                          <label  className="form-label required">
                        <Icon.Buildings/> Shipping Address
                        </label>
                           <Form.Control
                            // as="textarea"
                            value={shippingAddress}
                            ref={shippingAddressRef}
                            style={{ width: '100%', paddingLeft: 12, height: "45px"  }}
                            placeholder="Enter Shipping Address"
                            // rows="3" 
                            // style={{ resize: "none", height: '50px' }} 
                            onChange={(e) => {setShippingAddress(e.target.value)
                            if (shippingAddressError && e.target.value.trim() !== '') {
                                  setShippingAddressError(false);
                             } }}
                          />
                          <Form.Control.Feedback type="invalid" style={{ display: shippingAddressError ? 'block' : 'none' }}>
                          Shipping address is required
                          </Form.Control.Feedback> 
                        </Col>

                        <Col xl={6} lg={6} sm={6} style={{ marginBottom: '20px' }}>
                          <label  className="form-label required"><Icon.Geo/>Billing Address Pincode</label>
                            <Form.Control type="text" placeholder="Enter Pincode" value={pincodee} 
                            onChange={handleBillPincodeChange} ref={bpincodeRef}
                            style={{ width: '100%', paddingLeft: 12, height: "45px"  }}/>
                            <Form.Control.Feedback type="invalid" style={{ display: billingPincodeError ? 'block' : 'none' }}>
                          Billing Pincode is required
                        </Form.Control.Feedback> 
                        </Col>

                        <Col xl={6} lg={6} sm={6} style={{ marginBottom: '20px' }} >
                       <label  className="form-label required">
                        <Icon.Buildings/> Billing Address
                        </label>
                           <Form.Control
                            // as="textarea"
                            placeholder="Enter Billing Address"
                            value={billingAddress} 
                            style={{ width: '100%', paddingLeft: 12, height: "45px"  }}
                            ref={billingAddressRef}
                            // rows="3"
                            // style={{ resize: "none" }}
                            onChange={(e) => {setBillingAddress(e.target.value)
                            if (billingAddressError && e.target.value.trim() !== '') {
                                  setBillingAddressError(billingAddress);
                             } }
                            }
                          />
                          <Form.Control.Feedback type="invalid" style={{ display: billingAddressError ? 'block' : 'none' }}>
                          Billing address is required
                        </Form.Control.Feedback> 
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
          </Col>
          <Col xl={4} lg={4} style={{ paddingTop: 12 }}>
            <ul>
              <li>Order No:  {orderId}</li>
              <li>Order Date: {orderDate}</li>
              <li>Time: {getFormattedTimestamp()}</li>
              <li>Due Date:{' '} <RequiredAsterisk>*</RequiredAsterisk>
              <Form.Control.Feedback type="invalid" style={{ display: dueDateError ? 'block' : 'none' }}>
              Due Date is required
            </Form.Control.Feedback>
               <DatePicker
                selected={dueDate}
                onChange={handleDueDateChange}
                minDate={new Date()}
                dateFormat="MM-dd-yyyy"
                placeholderText="Select Due Date"
                className="custom-datepicker"
                ref={dueDateRef}
                open={datePickerOpen}
                onFocus={openDatePicker}
                onBlur={() => setDatePickerOpen(false)}
              />
            </li>
              <li>State of Supply  : {stateOfSupply}</li>
              <li>State of Delivery: {stateOfDelivery}</li>
            </ul>
          </Col>
        </Row>
        <Row style={{ marginTop: 16 }}>
          <Col className="mb-3">
            <InputGroup style={{ flexWrap: 'nowrap', border: '1px solid #ccc', borderRadius: 8 }}>
             <Typeahead
              ref={typeaheadRef}
                id="search-product"
                onInputChange={(text) => setProductSearch(text)}
                options={productOptions ? productOptions : []}
                renderMenuItemChildren={(option) =>{return option?.name; }} // Function to render each option
                labelKey="name" // Key in each object to use as the label
                placeholder="Search for a product..."
                onChange={(selected) => handleProductSelect(selected[0])}
                className='form-control border'
                style={{ width: '100%', paddingLeft: 50 }}
              />
              <FaSearch className='search-icons' />
              <Button onClick={()=>{ typeaheadRef.current.clear(); }} variant="outline-primary">Clear</Button>
            </InputGroup>
          </Col>
          <Col xl={12}> 
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    {/* <th colSpan="2">Discount</th> */}
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                  {/* <tr>
                    <th>Percentage</th>
                    <th>Value</th>
                  </tr> */}
                </thead>
                <tbody>
                  {selectedProducts.map((itemObj, index) => (
                    <EditItems 
                    item={itemObj}
                    index={index}
                    removeItem={removeItem}
                    onChange={(newData) => handleFormChange(index, newData)
                    }
                    /> 
                  ))}
                 {totalPrice != 0 && !isNaN(totalPrice) && (
                  <tr>
                    <td colSpan={3}></td>
                    <td><strong>Total</strong></td>
                    <td>{totalPrice}</td>
                    <td></td>
                  </tr>
                )}
                </tbody>
              </table>

          </Col>
        </Row>
        {/* <div className="text-right" style={{ marginTop: 16 }}></div> */}
        <div className="text-end" style={{ marginTop: 16 }}>
        {!isNaN(totalPrice)&& !hasEmptyQuantity&&totalPrice!=0 && !orderCreated && <Button variant="primary" disabled={loading} onClick={handleUpdateOrder}>
          Update Order
        </Button>} 
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
        
        </>
    )
}

export default EditOrder