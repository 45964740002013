import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Button from 'react-bootstrap/Button'; 
import AddCustomer from "./popup/addProducts";
import EditCustomer from "./popup/editProducts";
import { deleteProductById } from "../../services/Products/Product";
import './Productmaster.css'; 
import { useNavigate } from "react-router-dom";
import { getEnvUrl,getToken } from "../../utils/Api";
const $ = require('jquery')
$.DataTable = require('datatables.net')
function Productmaster() { 
  const [editProductID, setEditProductID] = useState(''); 
  const [AddcustomerModal, setAddcustomerModal] = useState(false);
  const [EditcustomerModal, setEditcustomerModal] = useState(false); 
  const [searchValue, setSearchValue] = useState('');
  const [tableRerander, setTableRerander] = useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const table = $(tableRef.current).DataTable({
      destroy: true,
      processing: true,
      serverSide: true,
      serverMethod: "get",
      ajax: {
        url: `${getEnvUrl()}/products/productPaginationData?search=${encodeURIComponent(searchValue)}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
         dataSrc: function (json) { 
          if(json.errorMessage!=undefined && (json.errorMessage=='Permission denied' || json.errorMessage === "Invalid token")){
           localStorage.clear();  
           navigate('/login')
          } else{
            return json.aaData
          } 
        }
   },
      columns: [
        { data: "id" },
        { data: "name" },
        { data: "description",
            render: function(data, type, row) {
              if (data.length < 100) {
                return data
              }
                const shortDescription = data.length > 100 ? data.substr(0, 100) + '...' : data;
                return `<div class="description">${shortDescription} <span class="read-more">Read More</span></div>`; 
            }
        },
        { data: "regular_price" },
        { data: "sale_price" },
        { data: "status" }, 
        { 
          data: null,
          title: 'Actions',
          render: (data, type, row) => {
            return `
            <div class="btn-group" role="group" aria-label="Basic example">
              <button class="btn btn-danger approvebtn">Edit</button>
              <button class="btn btn-danger ${(row.status=='instock')?'rejectbtn':'activatebtn'}">${(row.status=='instock')?'Out of stock':'In stock'}</button>
            </div>
          `; 
        
        },
        },
      ],
      'columnDefs': [{
        'targets': [6], /* column index */
        'orderable': false, /* true or false */ 
     }],
     createdRow: (row, data, dataIndex) => {  

      $(row).on('click', '.read-more', function(event) {
          event.preventDefault();
          const $description = $(this).closest('.description');
          const fullDescription = data.description;
          const isFull = $description.hasClass('full-description');

          if (isFull) {
              $description.removeClass('full-description');
              const shortDescription = data.length > 100 ? data.substr(0, 100) + '...' : data;
              $description.html(shortDescription + ' <span class="read-more">Read More</span>');
          } else {
              $description.addClass('full-description');
              $description.html(fullDescription + ' <span class="read-less">Read Less</span>');
          }
        });

        $(row).on('click', '.read-less', function(event) {
            event.preventDefault();
            const $description = $(this).closest('.description');
            const shortDescription = data.description.substr(0, 100) + '...';
            $description.removeClass('full-description');
            $description.html(shortDescription + ' <span class="read-more">Read More</span>');
        });

        $(row).find('.approvebtn').on('click', () => {          
          setEditProductID(data.id)
          setEditcustomerModal(true)
        });

        $(row).find('.rejectbtn,.activatebtn').on('click', function() {  
          var clsName=$(this).attr('class')
          var obj=$(this)
          var rowobj=$(row) 
          deleteProductById(data.id).then((res)=>{
            if(res.status){
              toast.success(res.message)
              if(clsName.includes('rejectbtn')){
                rowobj.find('td').eq(5).text('Out Of Stock')
                obj.removeClass('rejectbtn').addClass('activatebtn').text('In stock')
              }else{
                rowobj.find('td').eq(5).text('instock')
                obj.removeClass('activatebtn').addClass('rejectbtn').text('Out of stock')
              }   
             }else{
              toast.success('Somthing went worng')
             }
          }).catch((error)=>{
            toast.success('Somthing went worng')
          }) 
        });

        
      },
      
      drawCallback: function(settings) {
        // setIsLoading(false);
      },
      aaSorting: [[0, "desc"]],
      
    }); 
    return () => {
      // Destroy DataTable when component unmounts to avoid memory leaks
      // table.destroy();
    };
  }, [tableRerander,searchValue]); 
 
  let AddcustomerModalClose = () => {
    setAddcustomerModal(false)
  }
  let EditcustomerModalClose = () => {
    setEditcustomerModal(false)
  }
  
  let custDataRerander = () => {
    setTableRerander(!tableRerander)
  } 

  const divStyles = {
    boxShadow: '1px 2px 9px #000',
    'marginBottom': '20px',
    padding: '1em',
  };
  return (
    <div>  
      <div class="pagetitle"><h1>Product Master</h1><nav></nav></div>
      <div style={divStyles}>
        <Row>
          <Col xl={12} lg={12}>
            <Row>
              <Col className="d-flex justify-content-end">
                <FloatingLabel controlId="floatingInput">
                  <Button
                    variant="danger"
                    className="AddNewCustomer"
                    style={{ backgroundColor: '#FD1616', borderRadius: '30px', fontWeight: 500 }}
                    onClick={() => { setAddcustomerModal(true) }} >
                    <Icon.Cart4 color="black" size={15} />  Add Product
                  </Button>
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
      <div>
        <Row>
          <Col xl={12} lg={12}>

            <div className="card-body">
              <table
                className="table table-striped table-hover table-bordered no-footer dataTable"
                width="100%"
                ref={tableRef}
              >
                <thead>
                  <tr className="header">
                    <th style={{'width':'60px'}}>Product ID</th>
                    <th>Product Name</th>
                    <th>Description</th>
                    <th>Regular Price</th>
                    <th>Sale Price</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
            </div>
            <div>
            </div>
            
          </Col>
        </Row>
      </div>

      {AddcustomerModal ? <AddCustomer 
      custDataRerander={custDataRerander}
      isShow={AddcustomerModal} 
      close={AddcustomerModalClose} /> : ''}

    {EditcustomerModal ? <EditCustomer 
      custDataRerander={custDataRerander}     
      isEditShow={EditcustomerModal} 
      close={EditcustomerModalClose}
      editProductId={editProductID} /> : ''}  
    </div>

  )
  
}

export default Productmaster;