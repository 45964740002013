import { getEnvUrl, getToken,axiosInstance } from "../../utils/Api";
import axios from 'axios';
import { error } from "jquery";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
const customId = "searchUser";

export async function UserLogin(email, password) {
    try {
        const uniqueId = uuidv4(); 
        return await axiosInstance
            .post("/users/userLogin", {"email":email,"password":password,"deviceID":uniqueId})
            .then((data) => {
            return data;
            }) 
    } catch (error) {
        return false;
    }
}
 

export const getUserToken = () => {
  const tokenStr = localStorage.getItem("token");
  return tokenStr ? `Bearer ${tokenStr}` : null;
};

export const UserLogout = async (data) => { 
   
        return await axiosInstance
        .post("/users/userLogout",{},{headers: {
            Authorization: getUserToken(),
          }})
        .then((response) => {  
            return response.data;
        })  
}

const getDeviceID = async function () {
    return navigator.userAgent;
}

export async function generateUniqueId() {
    const input = await getDeviceID();
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const fullHash = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    const length = Math.floor(Math.random() * 3) + 8;
    return fullHash.substring(0, length);
}
 
export async function getUserData() {
        try { 
            const response =await axiosInstance
                    .get("/users/getUser", {
                    headers: {
                        Authorization:  getUserToken(),
                    },
                    })
                    .then((data) => {
                    return data
                    })

        if (response.status==200) { 
            return response.data;
        } else {
            console.error('Error fetching data:', response.status, response.statusText); 
            throw new Error(`Error fetching data: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        console.error('Error:', error.message); 
        throw error;
    }
}
export async function getUserDataByID(editUserId) {
    try {
        return await axiosInstance
        .get(`/users/getUserByID?id=${editUserId}`, {
        headers: {
            Authorization:  getUserToken(),
        },
        })
        .then((data) => {
        return data.data
        }) 
    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}

export async function getUserProfilePic() {
    try {
        const url = `${getEnvUrl()}/users/getProfilePic/${getToken()}`;
        const response = await axiosInstance.get(url, {
            responseType: 'blob',
        });

        if (response.status === 200) {
            const contentType = response.headers['content-type'];
            if (contentType && contentType.startsWith('image')) {
                const imageBlob = response.data;
                const imageUrl = URL.createObjectURL(imageBlob);
                return imageUrl;
            } else {
                console.error('Invalid content type for image:', contentType);
                throw new Error('Invalid content type for image');
            }
        } else {
            console.error('Error fetching data:', response.status, response.statusText);
            throw new Error(`Error fetching data: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}
 
export async function uploadProfileData(formData) {
  try { 
    const response = await axiosInstance
    .put("/users/updateProfilePic",formData,{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization:  getUserToken(),
        }
        })
    .then((data) => { 
    return data;
    })  
    if (response && response.data && response.data.status===true) {
      return response;
    } else {
      toast.error(response.data.errorMessage);
    }
  } catch (error) {
    console.error('Error:', error.message);
    // throw error;
  }
}

export async function updatePasswordData(data) {
  try { 
    const response = await axiosInstance
    .put("/users/updatePassword", JSON.stringify(data),{
        headers: {
            'Content-Type': 'application/json',
            Authorization:  getUserToken(),
        }
        })
    .then((data) => {
    return data;
    })  
    if (response.data.status===true){ 
      return response;
    } else {
      toast.error(response.data.errorMessage);
    }
  } catch (error) {
    console.error('Error:', error.message);
    throw error;
  }
}

export const createUserData = async (inputData) => {
    try {
        const response = await axiosInstance.post('/users/createUser', inputData, {
            headers: {
                Authorization: getUserToken(),
            },
        });
        if (response.data.status === true) {
            return response;
        }
        toast.error(response.data.errorMessage);
        return response;
    } catch (error) {
        console.error('Error in Add Customers:', error);
        return false;
    }
};

export const fetchUsers = async () => {
    try {
        const response = await axiosInstance.get('/users/usersData', {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response;
    } catch (error) {
        console.error('Error in Fetch Users:', error);
        return false;
    }
};

export const searchusers = async (user_name) => {
    try {
        const response = await axiosInstance.get('/users/searchUsersByName', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { user_name: user_name },
        });

        if (response.data.status === true) {
            return response;
        }
        return;
    } catch (error) {
        console.error('Error in Fetch User By Name:', error);
    }
};

export const searchusersByRole = async (user_name) => {
    try {
        const response = await axiosInstance.get('/users/searchUsersByName&Role', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { user_name: user_name },
        });

        if (response.data.status === true) {
            return response;
        }
    } catch (error) {
        console.error('Error in Fetch User By Role:', error);
    }
};
export const updateUsers = async (updatedData) => {
    try {
        const response = await axiosInstance.put('/users/updateUser', updatedData, {
            headers: {
                Authorization: getUserToken(),
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error in Edit Users:', error);
        return false;
    }
};

export const deleteUserById = async (deleteCustomerId) => {
    try {
        const response = await axiosInstance.delete('/users/deleteUser', {
            headers: {
                Authorization: getUserToken(),
            },
            params: { id: deleteCustomerId },
        });
        return response.data;
    } catch (error) {
        console.error('Error in Delete User By ID:', error);
        return false;
    }
};

export async function forgotPassword(email) {
    try {
        const response = await axiosInstance.post('/users/forgotPassword', { email }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status) {
            return response;
        } else {
            console.log('Error:', response.status);
        }
    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
}