import React from "react"
import { FaSearch, FaTrashAlt } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import  { useState } from 'react';

function EditItems({item,index,removeItem,onChange}){
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVol, setErrorMessageVol] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
        let newErrorMessage = '';
        if (name === "discount") {
            if (isNaN(value) || value < 0) {
                newValue = 0;
                newErrorMessage = "Please select a Discount of 0-100";
            } else if (value > 100) {
                newValue = 0;
                newErrorMessage = "Please select a Discount of 0-100";
            } else {
                newErrorMessage = '';
            }
            setErrorMessage(newErrorMessage);
        }
        
        if (name === "quantity") {
            if (isNaN(value) || value < 1) {
                newValue=" "
                newErrorMessage = "Please select a quantity of 1 or more.";
            }
            setErrorMessageVol(newErrorMessage);
        }

        if (name === "quantity") {
            let discount_amount = 0;
            let total_price = parseInt((item.unit_price * value) - (((item.unit_price * value) * item.discount) / 100)).toFixed(2);
            onChange({ ...item, [name]: newValue, ["discount_amount"]: discount_amount, ["total_price"]: total_price });
        } else if (name === "discount") {
            onChange({ ...item, [name]: newValue, ["discount_amount"]: (((item.unit_price * item.quantity) * newValue) / 100).toFixed(2),
            ["total_price"]: ((item.unit_price * item.quantity) - (((item.unit_price * item.quantity) * newValue) / 100)).toFixed(2) });
        }
    };
   return(
    <tr key={item?.product_id}>
        <td>{item?.product_id}</td>
        <td>{item?.item}</td>
        <td> <Form.Control
                type="text"
                name="quantity"
                value={item?.quantity} 
                placeholder="Vol"
                onChange={handleChange}
                style={{ width: "60px", display: "inline-block", marginRight: "5px" }}
            />
            {errorMessageVol && <span style={{ color: 'red', display: "inline-block"}}>{errorMessageVol}</span>}
        </td>
        {/* <td> 
            <Form.Select aria-label="Unit" style={{ width: "80px", display: "inline-block" }} >
                <option>--</option>
                <option value="1">kg</option>
                <option value="2">gm</option>
            </Form.Select>
        </td> */}
        <td>{item?.unit_price}</td>
         {/* <td>
            <Form.Control 
                type="text" 
                placeholder="%" 
                value={item?.discount} 
                name="discount"
                onChange={handleChange}
                style={{ width: "60px", display: "inline-block", marginRight: "5px" }} 
            />
            {errorMessage && <span style={{ color: 'red', display: "inline-block"}}>{errorMessage}</span>}
        </td> */}
        {/* <td>
        <Form.Control 
            readOnly
            type="text" 
            placeholder="%" 
            value={isNaN(item?.discount_amount) ? "0.00" : parseFloat(item?.discount_amount).toFixed(2)} 
            style={{ width: "80px", display: "inline-block", marginRight: "5px" }} 
        />
        </td> */}
        <td>{isNaN(item?.total_price) ? "0.00" : parseFloat(item?.total_price).toFixed(2)}</td>
         <td>
            <button onClick={()=>removeItem(item?.product_id)} style={{ background: 'transparent', border: 'none', outline: 'none' }}>
                <FaTrashAlt style={{ color: "red" }} /> 
            </button>
        </td>
    </tr>
   ) 
}
export default EditItems;