import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'; 
// import { useDispatch } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { styled } from "@mui/material/styles";
import { getUserDataByID, searchusers, updateUsers } from "../../../services/User/User";
import { fetchBranch, fetchDesignation } from "../../../services/Others/Others";
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form';

const EditUser = ({custDataRerander, isEditShow, close,editUsersId }) => { 
    const [userName, setUserName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [empNumber, setEmpNumber] = useState("");
    const [reportingTo, setReportingTo] = useState();
    const [branch, setBranch] = useState(""); 
    const [userData, setUserData] = useState([]);
    const [editUserId, setUserId] = useState("");
    const [user_type_id, setUserTypeID] = useState("");
    const [branchId, setBranchId] = useState("");
    const [reportingToId, setReportingToId] = useState("");
    const [branchData, setBranchData] = useState([]);
    const [designationData, setDesignationData] = useState([]);
    const [filteredReportingToOptions, setFilteredReportingToOptions] = useState([]);
    const [filteredBranchOptions, setFilteredBranchOptions] = useState([]);
    const [design, setDesign] = useState("");
    const [searchUserName, setSearchUserName] = useState("A");
    const [filteredDesignOptions, setFilteredDesignOptions] = useState([]);
    const [userNameError, setuserNameError] = useState(false);
    const [mobileNumberError, setMobileNumberError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [empNumberError, setempNumberError] = useState(false);
    const [reportingToError, setreportingToError] = useState(false);
    const [branchError, setbranchError] = useState(false);
    const [user_type_idError, setuser_type_ideError] = useState(false);
    const [loading, setLoading] = useState(false);
    const usernameRef = useRef(null);
    const mobileNumberRef = useRef(null);
    const emailRef = useRef(null);
    const empNumberRef = useRef(null);
    const branchRef = useRef(null);
    const reportingToRef = useRef(null);
    const userTypeRef = useRef(null);
    const RequiredAsterisk = styled("span")({
      color: "red",
    });
    const handleSave = async() => {

       if (!userName.trim() || !mobileNumber.trim() || !email.trim() || !empNumber.trim() || !reportingTo || !branch || !design ) {
          setuserNameError(!userName.trim())
          setMobileNumberError(!mobileNumber.trim())
          setEmailError(!email.trim())
          setbranchError(!branch)
          setuser_type_ideError(!design)
          setreportingToError(!reportingTo)  
          setempNumberError(!empNumber.trim())  

      let object=[{
      key:userName.trim(),
      ref:usernameRef.current,
      },
      {
        key:mobileNumber.trim(),
        ref:mobileNumberRef.current,
      },
      {
        key:email.trim(),
        ref:emailRef.current,
      },
      {
        key:empNumber.trim(),
        ref:empNumberRef.current,
      },
      {
        key:branch,
        ref:branchRef.current,
      },
      {
        key:design,
        ref:userTypeRef.current,
      },
      {
        key:reportingTo,
        ref:reportingToRef.current,
      },
    ]
    for(let i=0;i<object.length;i++){
      if (!object[i].key) {  
        object[i].ref.focus();
        break;
      } 
    }
     return
    }
    setuserNameError(false)
    setMobileNumberError(false)
    setEmailError(false)
    setbranchError(false)
    setuser_type_ideError(false)
    setempNumberError(false)
    setreportingToError(false)

       const updatedData = {
            id:editUserId,
            name: userName,
            phone_number: mobileNumber,
            email:email,
            user_type_id:user_type_id,
            emp_code: empNumber,
            branch: branchId,
            reportingTo: reportingToId, 
        };
        setLoading(true)
         updateUsers(updatedData).then(res=>{ 
          setLoading(false) 
          if(res.status==true){ 
            toast.success("User Modified successfully");
            handleClose();
          }else{  
            toast.error(res.errorMessage);
          }
         }).catch(erro=>{
          toast.success("User Modified successfully");
          setLoading(false)
         })
    // if (response && response.data&& response.data.status === true) {
    //         handleClose();
    //         custDataRerander(); 
    //         toast.success("User Modified successfully");
    //          setLoading(false)
    //     } else {
    //         setTimeout(() => {
    //             setLoading(false)
    //         }, 4000);  
    //     }
    };
    const handleBranchChange = (selected) => {
    const selectedBranch = branchData.find((branch) => branch.branch_name === selected[0]);
    setBranch(selected[0]);
    // setOutletNameError(false);
    
    if (selectedBranch) {
      setBranchId(selectedBranch.id);
      setbranchError(false)
    }
  };

  const handleBranchInputChange = (inputValue) => {
    if (inputValue.trim() !== '') {
      const filteredBranch = branchData.filter((branch) =>
        branch.branch_name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredBranchOptions(filteredBranch.map((branch) => branch.branch_name));
    } else {
      setFilteredBranchOptions([]);
    }
  };
  const handleDesignationChange = (selected) => {
    const selectedDesign = designationData.find((design) => design.name === selected[0]);
    setDesign(selected[0]);
    // setOutletNameError(false);
    
    if (selectedDesign) {
      setUserTypeID(selectedDesign.id);
      setuser_type_ideError(false)
    }
  };

  const handleDesignationInputChange = (inputValue) => {
    if (inputValue.trim() !== '') {
      const filteredDesign = designationData.filter((design) =>
        design.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredDesignOptions(filteredDesign.map((design) => design.name));
    } else {
      setFilteredDesignOptions([]);
    }
  };
    const handleUserNameChange = (selected) => {

    const selectedUser = userData.find((user) => user.name === selected[0]);
    setReportingTo(selected[0]);
    if (selectedUser) {
      setReportingToId(selectedUser.id);
    }
  };

  const handleUserInputChange = (inputValue) => {
    setSearchUserName(inputValue)
    if (inputValue.trim() !== '') {
    } else {
      setFilteredReportingToOptions([]);
    }
  };

   useEffect(() => {
    if (searchUserName) {
      searchusers(searchUserName)
        .then((response) => {
          setUserData(response.data.data)
          const userData = response.data.data;
          const filteredUsers = userData.filter((user) =>
            user.name.toLowerCase().includes(searchUserName.toLowerCase())
          );
          setFilteredReportingToOptions(filteredUsers.map((user) => user.name));
        })
        .catch((error) => {
          console.error('Error occurred:', error);
        });
    }
  }, [searchUserName]);
     

useEffect(() => {
    getUserDataByID(editUsersId)
    .then((response) => {
      if (response) {
        // setUserData(response.data);
        setUserName(response.data.name)
        setBranch(response.data.branchid.branch_name)
        setBranchId(response.data.branch)
        setEmail(response.data.email)
        setEmpNumber(response.data.emp_code)
        setMobileNumber(response.data.phone_number)
        setReportingTo(response.data.reportingUser.name)
        setReportingToId(response.data.reportingTo)
        setUserTypeID(response.data.user_type_id)
        setDesign(response.data.user_typeid.name)
        setUserId(response.data.id)
      }
    })
    .catch((error) => {
      console.error('Error fetching users:', error);
    });
     fetchDesignation()
    .then((response) => {
      if (response.data.data) {
        setDesignationData(response.data.data);
      }
    })
    .catch((error) => {
      console.error('Error fetching user:', error);
    });
    fetchBranch()
    .then((response) => {
      if (response.data.data) {
        setBranchData(response.data.data);
      }
    })
    .catch((error) => {
      console.error('Error fetching user:', error);
    });
}, [editUserId]);

    const handleClose = () => { setShow(false); close() };
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(isEditShow)
    }, [isEditShow]);
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl"> 
            <Modal.Header closeButton>
                <h4><Icon.PersonPlus /></h4>
                <h5 className="modal-title"> Edit</h5>
                {/* <h5 className="modal-title"> Edit User Data {editUsersId}</h5> */}
            </Modal.Header>
            <Modal.Body>
                <form className="row g-3 needs-validation" id="createCustomerForm" method="post" >
                    <div className="col-6 px-3">
                        <label htmlFor="NewCustomerName" className="form-label required">
                        <Icon.Person /> User Name <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="NewCustomerName"
                            name="customerName"
                            value={userName}
                            onChange={(e) => {setUserName(e.target.value)
                            if (userNameError && e.target.value.trim() !== '') {
                                  setuserNameError(false);
                             } }}
                            ref={usernameRef}
                            placeholder="Enter User Name"
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: userNameError ? 'block' : 'none' }}>
                            User name is required
                        </Form.Control.Feedback>
                        <div className="new-customer-name invalid-feedback">Please provide Customer Name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewMobileNumber" className="form-label required">
                        <Icon.Phone /> Mobile Number <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="NewMobileNumber"
                            name="mobileNumber"
                            value={mobileNumber}
                            onChange={(e) => {setMobileNumber(e.target.value)
                            if (mobileNumberError && e.target.value.trim() !== '') {
                                  setMobileNumberError(false);
                             } }}
                            ref={mobileNumberRef}
                            placeholder="Enter Mobile Number"
                            pattern="[0-9]*" 
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: mobileNumberError ? 'block' : 'none' }}>
                           Mobile number is required
                        </Form.Control.Feedback>
                        <div className="new-mobile-number invalid-feedback">Please provide a valid Mobile Number.</div>
                    </div>

                    <div className="col-6 px-3">
                        <label htmlFor="NewGameName" className="form-label required"><Icon.Envelope /> Email-ID <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input type="text" className="form-control" name="name" id="NewGameName"value={email}
                            onChange={(e) => {setEmail(e.target.value)
                            if (emailError && e.target.value.trim() !== '') {
                                  setEmailError(false);
                             } }}
                             ref={emailRef} placeholder="Enter User Email-ID" />
                            <Form.Control.Feedback type="invalid" style={{ display: emailError ? 'block' : 'none' }}>
                            Email-ID is required
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please provide name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewGameName" className="form-label required"><Icon.CashCoin /> Employee Code <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input type="text" className="form-control" name="name" id="NewGameName"value={empNumber}
                            onChange={(e) => {setEmpNumber(e.target.value)
                            if (empNumberError && e.target.value.trim() !== '') {
                                  setempNumberError(false);
                             } }} ref={empNumberRef} placeholder="Enter Employee Code" />
                            <Form.Control.Feedback type="invalid" style={{ display: empNumberError ? 'block' : 'none' }}>
                            Employee code is required
                        </Form.Control.Feedback>
                        <div className="new-game-name invalid-feedback">Please provide name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewBillingAddress" className="form-label required">
                        <Icon.Buildings/> Branch <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <Typeahead
                          id="Branch"
                          onChange={handleBranchChange}
                          options={branchData.map((branch) => branch.branch_name)}
                          onInputChange={handleBranchInputChange}
                          ref={branchRef}
                          placeholder="Select Branch "
                          selected={branch ? [branch] : []}
                          className='form-control border'
                          style={{ width: '100%', paddingLeft: 12 }}
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: branchError ? 'block' : 'none' }}>
                            Branch name is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Billing Address.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewShippingAddress" className="form-label required">
                        <Icon.Building/> Role <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <Typeahead
                          id="Branch"
                          onChange={handleDesignationChange}
                          options={designationData.map((design) => design.name)}
                          onInputChange={handleDesignationInputChange}
                          ref={userTypeRef}
                          placeholder="Select Role "
                          selected={design ? [design] : []}
                          className='form-control border'
                          style={{ width: '100%', paddingLeft: 12 }}
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: user_type_idError ? 'block' : 'none' }}>
                           Role is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Shipping Address.</div>
                    </div>
                     <div className="col-6 px-3">
                        <label htmlFor="NewShippingAddress" className="form-label required">
                        <Icon.Person /> Reporting To <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                       <Typeahead
                          id="salesExecutiveName"
                          onChange={handleUserNameChange}
                          options={filteredReportingToOptions}
                          onInputChange={handleUserInputChange}
                          ref={reportingToRef}
                          placeholder="Select Sales Executive "
                          selected={reportingTo ? [reportingTo] : []}
                          className='form-control border'
                          style={{ width: '100%', paddingLeft: 12, height: "45px"  }}
                        />
                         <Form.Control.Feedback type="invalid" style={{ display: reportingToError ? 'block' : 'none' }}>
                            Reporting To is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Shipping Address.</div>
                    </div>
                    
                    <div className="modal-footer" >
                        <Button variant="danger approvebtn" disabled={loading} onClick={handleSave}>Save</Button>
                        <Button variant="danger rejectbtn" onClick={handleClose}> Cancel</Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>

    );
};

export default EditUser;
