import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons'; 
// import { useDispatch } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { AddProduct } from "../../../services/Products/Product";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Form from 'react-bootstrap/Form';
import { styled } from "@mui/material/styles";
  const RequiredAsterisk = styled("span")({
    color: "red",
  });
const AddProducts = ({custDataRerander, isShow, close }) => {
    const handleShow = () => setShow(true);
    const [productName, setPeroductName] = useState("");
    const [description, setDescription] = useState("");
    const [regularPrice, setRegularPrice] = useState(null);
    const [salePrice, setSalePrice] = useState(null);
    const [productNameError, setproductNameError] = useState(false);
    const [salePriceError, setsalePriceError] = useState(false);
    const [descriptionError, setdescriptioneError] = useState(false);
    const [regularPriceError, setregularPriceError] = useState(false);
    const [loading, setLoading] = useState(false);
    const productNameRef = useRef(null);
    const descriptionRef = useRef(null);
    const regularPriceRef = useRef(null);
    const salePriceRef = useRef(null);

    const handleSave = async() => {
         if (!productName.trim() || !description.trim() || !regularPrice || !salePrice ) {
          setdescriptioneError(!productName.trim())
          setproductNameError(!description.trim()) 
          setregularPriceError(!regularPrice)  
          setsalePriceError(!salePrice)

    let object=[{
        key:productName.trim(),
        ref:productNameRef.current,
      },
      {
        key:description.trim(),
        ref:descriptionRef.current,
      },
      {
        key:regularPrice,
        ref:regularPriceRef.current,
      },
      {
        key:salePrice,
        ref:salePriceRef.current,
      },
    ]
    for(let i=0;i<object.length;i++){
      if (!object[i].key) {  
        object[i].ref.focus();
        break;
      } 
    }
     return
    }
    setdescriptioneError(false)
    setproductNameError(false)
    setregularPriceError(false)
    setsalePriceError(false)
       const inputData = {
            name: productName,
            description: description,
            regular_price:regularPrice,
            sale_price: salePrice,
        };
        setLoading(true)
        const response =  await AddProduct(inputData);
    if (response && response.data && response.data.status === true) {
            handleClose();
            custDataRerander();
            toast.success('Product Created Successfully');
            setLoading(false)
        } else {
            setTimeout(() => {
                setLoading(false)
            }, 4000);  
            // toast.error(` ${response.data.errorMessage}`);
        }
    };
     
    const handleClose = () => { setShow(false); close() };
    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(isShow)
    }, [isShow]);
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl"> 
            <Modal.Header closeButton>
                <h4><Icon.Cart4 /></h4>
                <h5 className="modal-title"> Add Product Data</h5>
            </Modal.Header>
            <Modal.Body>
                <form className="row g-3 needs-validation" id="createCustomerForm" method="post" >
                    <div className="col-6 px-3">
                        <label htmlFor="NewCustomerName" className="form-label required">
                        <Icon.Cart4 /> Product Name <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="NewCustomerName"
                            name="customerName"
                            value={productName}
                            ref={productNameRef}
                            onChange={(e) => {setPeroductName(e.target.value)
                            if (productNameError && e.target.value.trim() !== '') {
                                  setproductNameError(false);
                             } }}
                            placeholder="Enter Product Name"
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: productNameError ? 'block' : 'none' }}>
                            Product name is required
                        </Form.Control.Feedback>
                        <div className="new-customer-name invalid-feedback">Please provide Customer Name.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewMobileNumber" className="form-label required">
                        <Icon.CardText /> Description <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <textarea
                            type="text"
                            className="form-control"
                            id="NewMobileNumber"
                            name="mobileNumber"
                            value={description}
                            onChange={(e) => {setDescription(e.target.value)
                            if (descriptionError && e.target.value.trim() !== '') {
                                  setdescriptioneError(false);
                             } }}
                            ref={descriptionRef}
                            placeholder="Enter Description"
                            // pattern="[0-9]*" 
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: descriptionError ? 'block' : 'none' }}>
                            Description is required
                        </Form.Control.Feedback>
                        <div className="new-mobile-number invalid-feedback">Please provide a valid Mobile Number.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewBillingAddress" className="form-label required">
                        <Icon.CashCoin/> Regular Price <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="billingAddress"
                            id="NewBillingAddress"
                            value={regularPrice}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value) || value === "") {
                                    setRegularPrice(value);
                                    setregularPriceError(false)
                                }else
                                setRegularPrice("")
                            }}
                            ref={regularPriceRef}
                            placeholder="Enter Regular Price"
                            style={{ resize: "none" }}
                            pattern="\d*\.?\d*"
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: regularPriceError ? 'block' : 'none' }}>
                            Regular Price is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Billing Address.</div>
                    </div>
                    <div className="col-6 px-3">
                        <label htmlFor="NewBillingAddress" className="form-label required">
                        <Icon.CashCoin/> Sale Price <RequiredAsterisk>*</RequiredAsterisk>
                        </label>
                        <input
                            type="text" 
                            className="form-control"
                            name="billingAddress"
                            id="NewBillingAddress"
                            value={salePrice}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value) || value === "") {
                                    setSalePrice(value);
                                    setsalePriceError(false)
                                }else
                                setSalePrice("")
                            }}
                            ref={salePriceRef}
                            placeholder="Enter Sale Price"
                            style={{ resize: "none" }}
                        />
                        <Form.Control.Feedback type="invalid" style={{ display: salePriceError ? 'block' : 'none' }}>
                            Sale Price is required
                        </Form.Control.Feedback>
                        <div className="new-game-description invalid-feedback">Please provide Billing Address.</div>
                    </div>
                    <div className="modal-footer" >
                        <Button variant="danger approvebtn" disabled={loading} onClick={handleSave}>Save</Button>
                        <Button variant="danger rejectbtn" onClick={handleClose}> Cancel</Button>
                    </div>
                </form>
             </Modal.Body>
            <Modal.Footer>
          </Modal.Footer>
        </Modal>

    );
};

export default AddProducts;
